import React from 'react'
import Navbar from '../Components/Navbar'
import { Sidebar } from '../Components/Sidebar.tsx'
import { Card } from '../Components/Card'


const Plant = () => {
  return (
    <div className='plant'>
      <Sidebar />
      <Navbar />
      <Card />
    </div>
  )
}

export default Plant
