import React, { useState } from "react";
import "./plantform.css";
import axios from "axios";
import Swal from "sweetalert2";

export const PlantForm = () => {
  const [plantName, setPlantName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [startupDate, setStartupDate] = useState("");
  const [client, setClient] = useState("");
  const [country, setCountry] = useState("");
  const [location, setLocation] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);

  // Add error states for each input
  const [plantNameError, setPlantNameError] = useState("");
  const [capacityError, setCapacityError] = useState("");
  const [startupDateError, setStartupDateError] = useState("");
  const [clientError, setClientError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [latitudeError, setLatitudeError] = useState("");
  const [longitudeError, setLongitudeError] = useState("");

  const handleInputFocus = (fieldName) => {
    setFocusedInput(fieldName);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };

  const handleSubmit = () => {
    // Validate input fields
    let isValid = true;

    if (!plantName) {
      setPlantNameError("Plant Name is required");
      isValid = false;
    } else {
      setPlantNameError("");
    }

    if (!capacity) {
      setCapacityError("Capacity is required");
      isValid = false;
    } else {
      setCapacityError("");
    }

    if (!client) {
      setClientError("Client is required");
      isValid = false;
    } else {
      setClientError("");
    }

    if (!country) {
      setCountryError("Country is required");
      isValid = false;
    } else {
      setCountryError("");
    }

    if (!location) {
      setLocationError("Location is required");
      isValid = false;
    } else {
      setLocationError("");
    }

    if (!startupDate) {
      setStartupDateError("Startup Date is required");
      isValid = false;
    } else {
      setStartupDateError("");
    }

    if (!latitude) {
      setLatitudeError("Latitude is required");
      isValid = false;
    } else {
      setLatitudeError("");
    }

    if (!longitude) {
      setLongitudeError("Longitude is required");
      isValid = false;
    } else {
      setLongitudeError("");
    }

    if (!isValid) {
      return; // Don't submit if there are validation errors
    }

    // Create an object containing the form data
    const formData = {
      plantName: plantName,
      capacity: capacity,
      client: client,
      country: country,
      location: location,
      startupDate: startupDate,
      latitude: latitude,
      longitude: longitude,
    };

    const sendData = JSON.stringify(formData);

    // Send the formData object to your backend API using Axios
    axios
      .post("https://manconnect.net/mantrac-php/create-plant.php", sendData)
      .then((response) => {
        if (response.status === 202) {
          Swal.fire("Oops...", "Plant Already Exists", "error");
        } else if (response.status === 200) {
          Swal.fire(
            "Good Job!",
            "Plant Has Been Successfully Created",
            "success"
          );
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error(error);
      });
  };

  return (
    <div className="frame-container">
      <div className="frame-title">Basic Plant Information</div>
      <div className="card-title-line"></div>
      <div className="input-group">
        <div className="frame">
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "plantName" || plantName !== ""
                  ? "focused"
                  : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "plantName" ? "" : "Plant Name"
                        }
                        value={plantName}
                        onChange={(e) => {
                          setPlantName(e.target.value);
                          setPlantNameError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("plantName")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="plant-name">
                        Plant Name <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {plantNameError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {plantNameError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "capacity" || capacity !== "" ? "focused" : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "capacity" ? "" : "Capacity"
                        }
                        value={capacity}
                        onChange={(e) => {
                          setCapacity(e.target.value);
                          setCapacityError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("capacity")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="location">
                        Capacity <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {capacityError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {capacityError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="frame">
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "startupDate" || startupDate !== ""
                  ? "focused"
                  : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="date"
                        value={startupDate}
                        onChange={(e) => {
                          setStartupDate(e.target.value);
                          setStartupDateError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("startupDate")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="startup-date">
                        Startup Date <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {startupDateError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {startupDateError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "client" || client !== "" ? "focused" : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={focusedInput === "client" ? "" : "Client"}
                        value={client}
                        onChange={(e) => {
                          setClient(e.target.value);
                          setClientError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("client")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="client">
                        Client <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {clientError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {clientError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="frame">
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "country" || country !== "" ? "focused" : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "country" ? "" : "Country"
                        }
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          setCountryError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("country")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="country">
                        Country <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {countryError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {countryError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "location" || location !== "" ? "focused" : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "location" ? "" : "Location"
                        }
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value);
                          setLocationError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("location")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="location">
                        Location <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {locationError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {locationError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="frame">
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "latitude" || latitude !== "" ? "focused" : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "latitude" ? "" : "Latitude"
                        }
                        value={latitude}
                        onChange={(e) => {
                          setLatitude(e.target.value);
                          setLatitudeError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("latitude")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="latitude">
                        Latitude <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {latitudeError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {latitudeError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "longitude" || longitude !== ""
                  ? "focused"
                  : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "longitude" ? "" : "Longitude"
                        }
                        value={longitude}
                        onChange={(e) => {
                          setLongitude(e.target.value);
                          setLongitudeError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("longitude")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="longitude">
                        Longitude <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {longitudeError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {longitudeError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="save-button" onClick={handleSubmit}>
        Create Plant
      </button>
    </div>
  );
};
