import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import axios from "axios"; // Import axios for making API requests
import "./circle.css"; // Import your CSS file
import PieChart from "./pieChart";
import { DatePicker, Radio, ConfigProvider } from "antd";
import { useParams } from "react-router-dom";
import AreaChart from "./areaChart";

const Circular = () => {
  // function changeColor() {
  //   document.querySelector(".cls-3").setAttribute("style", "stroke: orange;");
  //   document.querySelector(".cls-4").setAttribute("style", "stroke: orange;");

  //   // Reset the color to default after 0.5 seconds (5000 - 4500)
  //   setTimeout(() => {
  //     document
  //       .querySelector(".cls-3")
  //       .setAttribute("style", "stroke: #DEDEDE;");
  //     document
  //       .querySelector(".cls-4")
  //       .setAttribute("style", "stroke: #DEDEDE;");
  //   }, 800);
  // }

  // function runInterval() {
  //   // Call the function every 10 seconds (4.5 seconds change + 0.5 seconds reset)
  //   setInterval(changeColor, 4500);
  // }

  // Initial call to start the first interval
  // runInterval();
  const { plant_id } = useParams();
  const [weatherData, setWeatherData] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Daily");
  const [plantName, setPlantName] = useState("");
  const [plantData, setPlantData] = useState([]);
  const [plantDatabyDate, setPlantDatabyDate] = useState([]);
  const [chartDatabyDate, setChartDatabyDate] = useState([]);
  const [realtimeData, setRealTimeData] = useState([]);
  const [date, setDate] = useState("");

  const seriesNames =
    chartDatabyDate && chartDatabyDate.length > 0
      ? Object.keys(chartDatabyDate[0]).filter(
          (name) =>
            name !== "Time" &&
            chartDatabyDate.every((entry) => entry[name] !== null) &&
            name !== "Ambient Temp(℃)" &&
            name !== "PV Module Temp(℃)"
        )
      : [];

  // Create chartData for the AreaChart component
  const formattedChartData = seriesNames.map((name) => ({
    name, // Series name
    values: chartDatabyDate.map((entry) => ({
      time: entry.Time, // X-axis label
      value: parseFloat(entry[name]), // Corresponding value
    })),
  }));

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handeDateChange = (date, dateString) => {
    console.log(dateString);
    setDate(dateString);
  };

  useEffect(() => {
    const fetchDate = async () => {
      try {
        // Fetch latitude and longitude from the backend
        const response = await axios.get(
          `https://manconnect.net/mantrac-php/plant-weather.php?plant_id=${plant_id}`
        );

        const { latitude, longitude } = response.data[0];

        console.log(response.data[0]);

        // Replace 'YOUR_API_KEY' with your actual OpenWeatherMap API key
        const apiKey = "877ff3605cfa7dda673bdacb0c7b9ae0";
        const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`;

        const weatherResponse = await axios.get(apiUrl);

        // Set the weather data in the state
        setWeatherData(weatherResponse.data);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };
    // Fetch weather data when the component mounts
    fetchDate();
  }, []);

  useEffect(() => {
    const fetchRealTimeData = async () => {
      try {
        const realTimeResponse = await axios.get(
          `https://manconnect.net/mantrac-php/real-time.php?plant_id=${plant_id}`
        );

        setRealTimeData(realTimeResponse.data);
      } catch (error) {
        console.error("Error fetching real-time data:", error);
      }
    };
    fetchRealTimeData();
  }, []);

  const fetchPlantInfo = async () => {
    try {
      // Fetch plant name from plant_info table based on plant_id
      const plantNameResponse = await axios.get(
        `https://manconnect.net/mantrac-php/plant-sidebar.php?plant_id=${plant_id}`
      );
      const originalPlantName = plantNameResponse.data[0].name;

      // Modify plant name to replace spaces with underscores
      const modifiedPlantName = originalPlantName.replace(/\s+/g, "_");

      const table_name = modifiedPlantName + "_history";

      // Set the modified plant name in the state
      setPlantName(table_name);

      // Fetch Monthly and Daily data based on the modified plant name
      const plantDataResponse = await axios.get(
        `https://manconnect.net/mantrac-php/monthly-daily-overview.php?table_name=${table_name}`
      );

      setPlantData(plantDataResponse.data);

      // You can handle the fetched data as needed
      console.log("Data:", plantDataResponse.data);
    } catch (error) {
      console.error("Error fetching plant info:", error);
    }
  };

  console.log(plantName);

  const fetchPlantInfoByDateAndOption = async () => {
    try {
      // Fetch Monthly and Daily data based on the modified plant name
      const plantDatabyDateResponse = await axios.get(
        `https://manconnect.net/mantrac-php/overview-by-date.php`,
        {
          params: {
            table_name: plantName,
            date: date,
            option: selectedOption,
          },
        }
      );

      setPlantDatabyDate(plantDatabyDateResponse.data);

      // You can handle the fetched data as needed
      console.log("DatabyDate:", plantDatabyDateResponse.data);
    } catch (error) {
      console.error("Error fetching plant info:", error);
    }
  };

  const fetchChartInfoByDateAndOption = async () => {
    try {
      const chartSensorbyDateResponse = await axios.get(
        `https://manconnect.net/mantrac-php/sensor-chart.php`,
        {
          params: {
            table_name: plantName,
            date: date,
            option: selectedOption,
          },
        }
      );

      // Fetch additional chart data using the modified plant name, date, and selected option
      const chartDatabyDateResponse = await axios.get(
        `https://manconnect.net/mantrac-php/overview-chart.php`,
        {
          params: {
            table_name: plantName,
            date: date,
            option: selectedOption,
          },
        }
      );

      // Combine the two responses into a single array
      const combinedChartData = chartSensorbyDateResponse.data.map(
        (entry, index) => ({
          ...entry,
          ...chartDatabyDateResponse.data[index],
        })
      );

      setChartDatabyDate(combinedChartData);

      // You can handle the fetched data as needed
      console.log("Chartttttt:", combinedChartData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    // Fetch plant info when the component mounts
    fetchPlantInfo();
  }, [plant_id]);

  useEffect(() => {
    // Fetch plant info based on date and selectedOption when date or selectedOption changes
    fetchPlantInfoByDateAndOption();
    fetchChartInfoByDateAndOption();
    // fetchSensorChartByDateAndOption();
  }, [date, selectedOption === "Total" ? selectedOption : undefined]);

  console.log(chartDatabyDate);
  console.log("realtimeData", realtimeData);
  // console.log(realtimeData[0].Communication);

  return (
    <div className="over">
      <div className="first-row">
        {/* {realtimeData.length > 0 && ( */}
        <div className="prog">
          <div className="plant-pr">Plant PR</div>
          <Progress
            percent={parseInt(
              realtimeData.length > 0 && realtimeData[0]["Plant PR"]
            )}
            size="small"
            showInfo={false}
          />
          <div className="num">
            {parseInt(realtimeData.length > 0 && realtimeData[0]["Plant PR"])}%
          </div>
        </div>
        {/* )} */}
        <div className="irradiance">
          <div>Irradiance</div>
          <div className="irrunit">
            {realtimeData.length > 0 && realtimeData[0]["Irradiance(W/m2)"]}{" "}
            W/m2
          </div>
        </div>
        <div className="today">
          <div className="tod-data">
            <div className="tod-line"></div>
            <div className="Today">Today</div>
            <div className="data-title">Load Consumption</div>
            <div className="tod-unit">
              {plantData["Daily Load Consumption(kWh)"]} kWh
            </div>

            <div className="data-title">Grid Consumption</div>
            <div className="tod-unit">
              {plantData["Daily Grid Consumption(kWh)"]} kWh
            </div>
            <div className="data-title">Solar Yield</div>
            <div className="tod-unit">
              {plantData["Daily Solar Yield(kWh)"]} kWh
            </div>
            <div className="data-title">Solar Feed-in</div>
            <div className="tod-unit">
              {plantData["Daily Feed-in(kWh)"]} kWh
            </div>
            <div className="data-title">Solar Self-Consumption</div>
            <div className="tod-unit">
              {plantData["Daily Self-Consumption(kWh)"]} kWh
            </div>
          </div>
        </div>

        <div className="diff-line"></div>

        <div className="this-month">
          <div className="month-data">
            <div className="month-line"></div>
            <div className="Month">This Month</div>
            <div className="data-title">Load Consumption</div>
            <div className="month-unit">
              {plantData["Monthly Load Consumption(kWh)"]} kWh
            </div>
            <div className="data-title">Grid Consumption</div>
            <div className="month-unit">
              {plantData["Monthly Grid Consumption(kWh)"]} kWh
            </div>
            <div className="data-title">Solar Yield</div>
            <div className="month-unit">
              {plantData["Monthly Solar Yield(kWh)"]} kWh
            </div>
            <div className="data-title">Solar Feed-in</div>
            <div className="month-unit">
              {plantData["Monthly Feed-in(kWh)"]} kWh
            </div>
            <div className="data-title">Solar Self-Consumption</div>
            <div className="month-unit">
              {plantData["Monthly Self-Consumption(kWh)"]} kWh
            </div>
          </div>
        </div>
        <div className="circle-line"></div>
        <div className="circular-layout">
          {/* {realtimeData.length > 0 && realtimeData[0].Communication !== 0 && ( */}
          <svg
            className="clock"
            width="44px"
            height="44px"
            viewBox="0 0 24.00 24.00"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            stroke="#000000"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />

            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke=""
              strokeWidth="0.144"
            />

            <g id="SVGRepo_iconCarrier">
              <defs></defs>

              <circle
                cx="12"
                cy="13.91"
                r="8.59"
                fill="#ffffff"
                stroke="#DEDEDE"
              ></circle>
              <g id="animatedGroup">
                <line
                  x1="12"
                  y1="13.91"
                  x2="12"
                  y2="7.91"
                  stroke="#DEDEDE"
                  className={
                    realtimeData.length > 0 &&
                    realtimeData[0].Communication !== "0"
                      ? "cls-2"
                      : ""
                  }
                />

                {realtimeData.length > 0 &&
                  realtimeData[0].Communication != 0 && (
                    <circle
                      className="circle-1"
                      cx="9.9"
                      cy="11.91"
                      r="8.59"
                      fill="transparent"
                      stroke="orange"
                    />
                  )}
              </g>

              <circle
                cx="12"
                cy="13.91"
                r="1"
                fill="#DEDEDE"
                stroke="#DEDEDE"
              />

              <line
                className={
                  realtimeData.length > 0 &&
                  realtimeData[0] &&
                  realtimeData[0].Communication !== "0"
                    ? "cls-1"
                    : "cl-1"
                }
                x1="12"
                y1="1.5"
                x2="12"
                y2="5.32"
                // style="stroke: orange;"
              />

              <line
                className={
                  realtimeData.length > 0 &&
                  realtimeData[0].Communication !== "0"
                    ? "cls-1"
                    : "cl-1"
                }
                x1="11.09"
                y1="1.5"
                x2="13.91"
                y2="1.5"
                // stroke="orange"
              />

              <line
                className={
                  realtimeData.length > 0 &&
                  realtimeData[0].Communication !== "0"
                    ? "cls-3"
                    : "cl-3"
                }
                x1="11.1"
                y1="1.5"
                x2="11.1"
                y2="5.32"
              />

              {/* Conditionally render className for line cls-4 */}
              <line
                className={
                  realtimeData.length > 0 &&
                  realtimeData[0].Communication !== "0"
                    ? "cls-4"
                    : "cl-4"
                }
                x1="9.5"
                y1="1.5"
                x2="11.61"
                y2="1.5"
              />

              {/* <line x1="12" y1="13.91" x2="12" y2="7.91" className="cls-2">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 12 13.91"
                to="360 12 13.91"
                dur="5s"
                repeatCount="indefinite"
              />
            </line> */}
            </g>
          </svg>
          {/* )} */}
          {weatherData && (
            <div className="weather">
              <div className="weather-day">Today</div>
              <div className="weather-line"></div>
              <div className="weather-data">
                {/* <div className="weather-icon"> */}
                {/* Construct the URL for the weather icon based on the provided icon code */}
                <img
                  src={`http://openweathermap.org/img/wn/${weatherData.weather[0].icon}.png`}
                  alt={weatherData.weather[0].description}
                />
                {/* </div> */}
                {Math.floor(weatherData.main.temp_min)}~
                {Math.floor(weatherData.main.temp_max)}°C
              </div>
            </div>
          )}
          <div className="circle-data">
            {/* {realtimeData.length > 0 &&
              realtimeData[0].Communication !== "0" && (
                <div> */}
            {realtimeData.length > 0 &&
            ((realtimeData[0]["Grid Active Power(kW)"] < 0 &&
              realtimeData[0]["Solar Active Power(kW)"] > 0) ||
              (realtimeData[0]["Grid Active Power(kW)"] == 0 &&
                realtimeData[0]["Solar Active Power(kW)"] > 0)) ? (
              <div className="solar-load">
                {`${(
                  parseFloat(realtimeData[0]["Grid Active Power(kW)"]) +
                  parseFloat(realtimeData[0]["Solar Active Power(kW)"])
                ).toFixed(2)} kW`}
              </div>
            ) : (
              realtimeData.length > 0 &&
              realtimeData[0]["Grid Active Power(kW)"] > 0 &&
              realtimeData[0]["Solar Active Power(kW)"] > 0 && (
                <div className="solar-load2">
                  {`${realtimeData[0]["Solar Active Power(kW)"]} kW`}
                </div>
              )
            )}

            {realtimeData.length > 0 &&
              realtimeData[0]["Grid Active Power(kW)"] < 0 &&
              realtimeData[0]["Solar Active Power(kW)"] > 0 && (
                <div className="solar-grid">
                  {`${realtimeData[0]["Grid Active Power(kW)"]} kW`}
                </div>
              )}
            {realtimeData.length > 0 &&
              ((realtimeData[0]["Grid Active Power(kW)"] > 0 &&
                realtimeData[0]["Solar Active Power(kW)"] == 0) ||
                (realtimeData[0]["Grid Active Power(kW)"] > 0 &&
                  realtimeData[0]["Solar Active Power(kW)"] > 0)) && (
                <div className="grid-load">
                  {`${realtimeData[0]["Grid Active Power(kW)"]} kW`}
                </div>
              )}

            <div className="solar-data">
              {realtimeData.length > 0
                ? `${
                    realtimeData[0]["Solar Active Power(kW)"] != null
                      ? realtimeData[0]["Solar Active Power(kW)"]
                      : "N/A"
                  } kW`
                : "N/A kW"}
            </div>

            <div className="load-data">
              {realtimeData.length > 0
                ? `${
                    realtimeData[0]["Load Active Power(kW)"] != null
                      ? realtimeData[0]["Load Active Power(kW)"]
                      : "N/A"
                  } kW`
                : "N/A kW"}
            </div>

            <div className="grid-data">
              {realtimeData.length > 0
                ? `${
                    realtimeData[0]["Grid Active Power(kW)"] != null
                      ? realtimeData[0]["Grid Active Power(kW)"]
                      : "N/A"
                  } kW`
                : "N/A kW"}
            </div>

            {/* </> */}
            {/* )} */}
            <div className="the-circle">
              <svg width="300" height="300" xmlns="http://www.w3.org/2000/svg">
                {/* Circle */}
                <circle
                  cx="150"
                  cy="150"
                  r="120"
                  stroke="rgb(204, 204, 204)"
                  strokeWidth="2"
                  fill="transparent"
                />

                {/* Arrowheads along the paths using animateMotion and rotate */}
                <g
                  style={{
                    display:
                      realtimeData.length > 0 &&
                      realtimeData[0].Communication == 0
                        ? "none"
                        : "block",
                  }}
                >
                  <g
                    style={{
                      display:
                        (realtimeData.length > 0 &&
                          realtimeData[0]["Grid Active Power(kW)"] < 0 &&
                          realtimeData[0]["Solar Active Power(kW)"] > 0) ||
                        (realtimeData.length > 0 &&
                          realtimeData[0]["Grid Active Power(kW)"] == 0 &&
                          realtimeData[0]["Solar Active Power(kW)"] > 0) ||
                        (realtimeData.length > 0 &&
                          realtimeData[0]["Grid Active Power(kW)"] == 0 &&
                          realtimeData[0]["Solar Active Power(kW)"] == 0)
                          ? "none"
                          : "block",
                    }}
                  >
                    <polygon points="0,0 10,4 10,-4" fill="orange">
                      {/* Animation for circlePath1 */}
                      <animateMotion repeatCount="indefinite" dur="5s">
                        <mpath href="#gridtoload" />
                      </animateMotion>
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="-45"
                        to="40"
                        begin="0s"
                        dur="5s"
                        repeatCount="indefinite"
                      />
                    </polygon>

                    <path
                      id="gridtoload"
                      d="M 229 240 A 120 120 0 0 1 70 240"
                      fill="transparent"
                      strokeWidth="2"
                      stroke="orange"
                    />
                  </g>
                  <g
                    style={{
                      display:
                        (realtimeData.length > 0 &&
                          realtimeData[0]["Grid Active Power(kW)"] > 0 &&
                          realtimeData[0]["Solar Active Power(kW)"] == 0) ||
                        (realtimeData.length > 0 &&
                          realtimeData[0]["Grid Active Power(kW)"] == 0 &&
                          realtimeData[0]["Solar Active Power(kW)"] > 0) ||
                        (realtimeData.length > 0 &&
                          realtimeData[0]["Grid Active Power(kW)"] > 0 &&
                          realtimeData[0]["Solar Active Power(kW)"] > 0) ||
                        (realtimeData.length > 0 &&
                          realtimeData[0]["Grid Active Power(kW)"] == 0 &&
                          realtimeData[0]["Solar Active Power(kW)"] == 0)
                          ? "none"
                          : "block",
                    }}
                  >
                    <polygon points="0,0 -10,4 -10,-4" fill="orange">
                      <animateMotion repeatCount="indefinite" dur="5s">
                        <mpath href="#solartogrid" />
                      </animateMotion>
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="8"
                        to="105"
                        begin="0s"
                        dur="5s"
                        repeatCount="indefinite"
                      />
                    </polygon>

                    <path
                      id="solartogrid"
                      d="M 170 32 A 120 120 0 0 1 264 190"
                      fill="transparent"
                      strokeWidth="2"
                      stroke="orange"
                    />
                  </g>

                  <g
                    style={{
                      display:
                        (realtimeData.length > 0 &&
                          realtimeData[0]["Solar Active Power(kW)"] == 0 &&
                          realtimeData[0]["Grid Active Power(kW)"] > 0) ||
                        (realtimeData.length > 0 &&
                          realtimeData[0]["Grid Active Power(kW)"] == 0 &&
                          realtimeData[0]["Solar Active Power(kW)"] == 0)
                          ? "none"
                          : "block",
                    }}
                  >
                    <polygon points="0,0 10,4 10,-4" fill="orange">
                      {/* Animation for circlePath3 */}
                      <animateMotion repeatCount="indefinite" dur="5s">
                        <mpath href="#solartoload" />
                      </animateMotion>
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="-8"
                        to="-110"
                        begin="0s"
                        dur="5s"
                        repeatCount="indefinite"
                      />
                    </polygon>

                    <path
                      id="solartoload"
                      d="M 130 32 A 120 120 0 0 0 37 190"
                      fill="transparent"
                      strokeWidth="2"
                      stroke="orange"
                    />
                  </g>
                </g>

                {/* Image */}
                <image href="/images/solar-panel.svg" x="115" y="-40" />

                {/* Points */}
                {/* <div className="circle-image"> */}
                <image
                  x="25"
                  y="135"
                  href="/images/electricity-home.svg"
                  // width="40"
                  // height="40"
                />
                {/* </div> */}
                <image x="212" y="135" href="/images/electric-tower.svg" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="the-date">
          <ConfigProvider
            theme={{
              components: {
                Radio: {
                  // Seed Token
                  buttonBg: "#d1d1d1",

                  // Alias Token

                  buttonSolidCheckedBg: "orange",
                  buttonSolidCheckedHoverBg: "orange",
                },
              },
              token: {
                borderRadius: 2,
              },
            }}
          >
            <Radio.Group
              className="the-radio"
              onChange={handleRadioChange}
              value={selectedOption}
              optionType="button"
              buttonStyle="solid"
              size="middle"
            >
              <Radio value="Daily" className="radio-option">
                Day
              </Radio>
              <Radio value="Monthly" className="radio-option">
                Month
              </Radio>
              <Radio value="Annual" className="radio-option">
                Year
              </Radio>
              <Radio value="Total" className="last-radio-option">
                Total
              </Radio>
            </Radio.Group>
          </ConfigProvider>

          {selectedOption === "Daily" && (
            <DatePicker
              size="middle"
              style={{
                width: "20%",
              }}
              onChange={handeDateChange}
            />
          )}
          {selectedOption === "Monthly" && (
            <DatePicker
              // Customize options for the second DatePicker as needed
              size="middle"
              picker="month"
              style={{
                width: "20%",
              }}
              onChange={handeDateChange}
            />
          )}
          {selectedOption === "Annual" && (
            <DatePicker
              // Customize options for the third DatePicker as needed
              size="middle"
              picker="year"
              style={{
                width: "20%",
              }}
              onChange={handeDateChange}
            />
          )}
        </div>
      </div>
      <div className="second-row">
        <div className="pv">
          <div>
            PV <br />
            <span>
              {plantDatabyDate &&
              plantDatabyDate[`${selectedOption} Solar Yield(kWh)`] != null
                ? `${plantDatabyDate[`${selectedOption} Solar Yield(kWh)`]} kWh`
                : "N/A"}
            </span>
          </div>
        </div>
        <PieChart
          className="first-pie"
          chartId="first-pie"
          data={[
            {
              value:
                plantDatabyDate &&
                plantDatabyDate[`${selectedOption} Self-Consumption(kWh)`] !=
                  null
                  ? (
                      (plantDatabyDate[
                        `${selectedOption} Self-Consumption(kWh)`
                      ] /
                        (plantDatabyDate[
                          `${selectedOption} Solar Yield(kWh)`
                        ] || 1)) *
                      100
                    ).toFixed(2)
                  : 0,
              name: "Self-Consumption",
              color: "blue",
            },
            {
              value:
                plantDatabyDate &&
                plantDatabyDate[`${selectedOption} Feed-in(kWh)`] != null
                  ? (
                      (plantDatabyDate[`${selectedOption} Feed-in(kWh)`] /
                        (plantDatabyDate[
                          `${selectedOption} Solar Yield(kWh)`
                        ] || 1)) *
                      100
                    ).toFixed(2)
                  : 0,
              name: "Feed-in Energy from PV",
              color: "green",
            },
          ]}
        />
        <div className="first-data">
          <div className="blue">Self-Consumption</div>
          <div>
            {plantDatabyDate &&
            plantDatabyDate[`${selectedOption} Self-Consumption(kWh)`] != null
              ? `${
                  plantDatabyDate[`${selectedOption} Self-Consumption(kWh)`]
                } kWh (${(
                  (plantDatabyDate[`${selectedOption} Self-Consumption(kWh)`] /
                    (plantDatabyDate[`${selectedOption} Solar Yield(kWh)`] ||
                      1)) *
                  100
                ).toFixed(2)}%)`
              : "N/A"}
          </div>
          <div className="feed-in">
            <div className="green">Feed-in Energy from PV</div>
            <div>
              {plantDatabyDate &&
              plantDatabyDate[`${selectedOption} Feed-in(kWh)`] != null
                ? `${plantDatabyDate[`${selectedOption} Feed-in(kWh)`]} kWh (${(
                    (plantDatabyDate[`${selectedOption} Feed-in(kWh)`] /
                      (plantDatabyDate[`${selectedOption} Solar Yield(kWh)`] ||
                        1)) *
                    100
                  ).toFixed(2)}%)`
                : "N/A"}
            </div>
          </div>
        </div>

        <div className="pie-line"></div>
        <div className="load">
          <div>
            Load <br />
            <span>
              {plantDatabyDate &&
              plantDatabyDate[`${selectedOption} Load Consumption(kWh)`] != null
                ? `${
                    plantDatabyDate[`${selectedOption} Load Consumption(kWh)`]
                  } MWh`
                : "N/A"}
            </span>
          </div>
        </div>
        <PieChart
          chartId="second-pie"
          data={[
            {
              value:
                plantDatabyDate &&
                plantDatabyDate[`${selectedOption} Self-Consumption(kWh)`] !=
                  null
                  ? (
                      (plantDatabyDate[
                        `${selectedOption} Self-Consumption(kWh)`
                      ] /
                        (plantDatabyDate[
                          `${selectedOption} Load Consumption(kWh)`
                        ] || 1)) *
                      100
                    ).toFixed(2)
                  : 0,
              name: "Self-Sufficiency",
              color: "orange",
            },
            {
              value:
                plantDatabyDate &&
                plantDatabyDate[`${selectedOption} Grid Consumption(kWh)`] !=
                  null
                  ? (
                      (plantDatabyDate[
                        `${selectedOption} Grid Consumption(kWh)`
                      ] /
                        (plantDatabyDate[
                          `${selectedOption} Load Consumption(kWh)`
                        ] || 1)) *
                      100
                    ).toFixed(2)
                  : 0,
              name: "Purchased Energy",
              color: "red",
            },
          ]}
        />
        <div className="second-data">
          <div className="orange">Self-Sufficiency</div>
          <div>
            {plantDatabyDate &&
            plantDatabyDate[`${selectedOption} Self-Consumption(kWh)`] != null
              ? `${
                  plantDatabyDate[`${selectedOption} Self-Consumption(kWh)`]
                } kWh (${(
                  (plantDatabyDate[`${selectedOption} Self-Consumption(kWh)`] /
                    (plantDatabyDate[
                      `${selectedOption} Load Consumption(kWh)`
                    ] || 1)) *
                  100
                ).toFixed(2)}%)`
              : "N/A"}
          </div>
          <div className="purchased-energy">
            <div className="red">Purchased Energy</div>
            {/* <div>
              {plantDatabyDate &&
              plantDatabyDate[`${selectedOption} Purchased Energy(kWh)`] !=
                null
                ? `${
                    plantDatabyDate[`${selectedOption} Purchased Energy(kWh)`]
                  } kWh (70.94%)` // Replace 70.94% with the actual percentage calculation
                : "N/A"}
            </div> */}
            <div>
              {plantDatabyDate &&
              plantDatabyDate[`${selectedOption} Grid Consumption(kWh)`] != null
                ? `${
                    plantDatabyDate[`${selectedOption} Grid Consumption(kWh)`]
                  } kWh (${(
                    (plantDatabyDate[
                      `${selectedOption} Grid Consumption(kWh)`
                    ] /
                      (plantDatabyDate[
                        `${selectedOption} Load Consumption(kWh)`
                      ] || 1)) *
                    100
                  ).toFixed(2)}%)`
                : "N/A"}
            </div>
          </div>
        </div>
      </div>

      <div className="third-row">
        {selectedOption !== "Total" && !date ? (
          <div className="select-date">No Date Selected</div>
        ) : formattedChartData.length > 0 ? (
          <AreaChart
            chartData={formattedChartData}
            selectedOption={selectedOption}
          />
        ) : (
          <div className="select-date">No Data Found</div>
        )}
      </div>
    </div>
  );
};

export default Circular;
