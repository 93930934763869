import './verification.css'
import axios from 'axios'
import { useState } from 'react'

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})

  const validate = () => {
    const newErrors = {}

    if (email.length === 0) {
      newErrors.email = 'Email is required'
    }

    return newErrors
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const newErrors = validate()
    setErrors(newErrors)
    if (Object.keys(newErrors).length === 0) {
      axios
        .post('https://manconnect.net/mantrac-php/reset-password.php', {
          email,
        })
        .then((response) => {
          console.log(response.data)
          if (response.status == '200') {
            alert('Check Your Email')
          } else {
            setErrors((prevState) => ({
              ...prevState,
              email: 'User Does Not Exist',
            }))
          }
        })
    }
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <div className="verification">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <img
            className="form-img"
            src="https://www.mantracgroup.com/media/23rdrnwg/mansour-timeline-2000-2x-1024x768.jpg?width=470"
            alt=""
          ></img>
          <h3 className="form-title">
            <b>Reset Password</b>
          </h3>
          <input
            type="email"
            placeholder="Email"
            className="form-control mt-3"
            value={email}
            onChange={handleEmailChange}
          />
          {errors.email && (
            <p id="alert" className="alert">
              {errors.email}
            </p>
          )}
          <div className="d-grid gap-1 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
