import { FC, RefObject, useRef, useState } from "react";
import React from "react";
import "./dropdown.css";
import { decrypt } from "n-krypta";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import WidgetsIcon from "@mui/icons-material/Widgets";
import LockIcon from "@mui/icons-material/Lock";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const icons = {
  account_circle: <AccountCircleIcon />,
  more: <ExpandMoreIcon />,
  close: <CloseIcon />,
  dark_mode: <DarkModeIcon />,
  widgets: <WidgetsIcon />,
  lock: <LockIcon />,
  admin: <AdminPanelSettingsIcon />,
};

type IconProps = {
  children: string;
  className?: string;
  iconRef?: RefObject<HTMLSpanElement>;
};

const Icon: FC<IconProps> = ({ children, iconRef, className }) => (
  <span
    ref={iconRef}
    className={`${className || ""} material-symbols-outlined`}
  >
    {icons[children]}
  </span>
);

const Dropdown = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const chevronRef = useRef<HTMLSpanElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const decryptedObject = decrypt(
    localStorage.getItem("LOGIN-INFO") ?? "",
    "mantrac1234"
  );
  // const [menuTop, setMenuTop] = useState<string>();
  // const [menuRight, setMenuRight] = useState<string>();

  const handleClick = () => {
    // const buttonRect = buttonRef?.current?.getBoundingClientRect();
    // const chevronRect = chevronRef?.current?.getBoundingClientRect();

    // if (buttonRect && chevronRect && isOpen) {
    //   const menuRight = buttonRect.right - chevronRect.right;
    //   const menuTop = chevronRect.top - buttonRect.top;
    //   setMenuRight(`${menuRight}px`);
    //   setMenuTop(`${menuTop}px`);
    // } else {
    //   setMenuRight("0");
    //   setMenuTop("78px");
    // }

    setIsOpen(!isOpen);
  };

  const logout = () => {
    localStorage.clear();
    window.location.replace("/login");
  };

  return (
    <div className={`dropdown ${isOpen ? "open" : ""}`}>
      <button ref={buttonRef} onClick={handleClick}>
        <Icon className="account">account_circle</Icon>
        <Icon
          iconRef={chevronRef}
          className="chevron material-symbols-outlined"
        >
          {isOpen ? "close" : "more"}
        </Icon>
      </button>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        {/* <button>
          <Icon>dark_mode</Icon>
          <span>Dark Mode</span>
        </button> */}
        {decryptedObject.role === "Admin" && (
          <button>
            <a className="requests_anchor" href="requests">
              <Icon>admin</Icon>
              Requests
            </a>
          </button>
        )}
        <button onClick={logout}>
          <Icon>lock</Icon>
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Dropdown;
