import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const passwordRegex = /^([a-zA-Z0-9@*#]{8,})$/

const SignUp = () => {
  const navigate = useNavigate()
  const [data, setData] = useState({
    name: '',
    companyName: '',
    email: '',
    password: '',
    confirmpassword: '',
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const validate = () => {
    const newErrors = {}

    if (data.name.length === 0) {
      newErrors.name = 'Name is required'
    } else if (data.companyName.length === 0) {
      newErrors.comapnyName = 'Company Name is required'
    } else if (data.email.length === 0) {
      newErrors.email = 'Email is required'
    } else if (data.password.length === 0) {
      newErrors.password = 'Password is required'
    } else if (!passwordRegex.test(data.password)) {
      newErrors.password = 'Password Should Be At least 8 Charchters'
    } else if (data.confirmpassword.length === 0) {
      newErrors.confirmpassword = 'Confirm Password is required'
    } else if (data.password !== data.confirmpassword) {
      newErrors.confirmpassword = 'Password Mismatch'
    }

    return newErrors
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const newErrors = validate()
    setErrors(newErrors)
    if (Object.keys(newErrors).length === 0) {
      const sendData = {
        name: data.name,
        companyName: data.companyName,
        email: data.email,
        password: data.password,
        confirmpassword: data.confirmpassword,
      }

      const url = 'https://manconnect.net/mantrac-php/signup.php'

      axios
        .post(url, sendData)
        .then((result) => {
          if (result.status == '202') {
            alert('User Already Exist')
          } else {
            // alert(result.status)
            Swal.fire(
              'Congratulations!',
              'You Your Account Have Been Made Check Your Email To Activate Your Account',
              'success',
            )
            navigate('/login')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
  return (
    <div className="login-form-container">
      <form className="login-form" onSubmit={submitHandler}>
        <div className="login-form-content">
          <img
            className="form-img"
            src="https://www.mantracgroup.com/media/23rdrnwg/mansour-timeline-2000-2x-1024x768.jpg?width=470"
            alt=""
          ></img>
          <h3 className="login-form-title">Sign Up</h3>
          <div className="form-group mt-1">
            <input
              type="text"
              name="name"
              className="form-control mt-1"
              placeholder="Name"
              onChange={handleChange}
              value={data.name}
            />
            {errors.name && (
              <p id="alert" className="alert">
                {errors.name}
              </p>
            )}
          </div>
          <div className="form-group mt-1">
            <input
              type="text"
              name="companyName"
              className="form-control mt-1"
              placeholder="Company Name"
              onChange={handleChange}
              value={data.companyName}
            />
            {errors.comapnyName && (
              <p id="alert" className="alert">
                {errors.comapnyName}
              </p>
            )}
          </div>
          <div className="form-group mt-1">
            <input
              type="email"
              name="email"
              className="form-control mt-1"
              placeholder="Enter email"
              onChange={handleChange}
              value={data.email}
            />
            {errors.email && (
              <p id="alert" className="alert">
                {errors.email}
              </p>
            )}
          </div>
          <div className="form-group mt-1">
            <input
              type="password"
              name="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={handleChange}
              value={data.password}
            />
            {errors.password && (
              <p id="alert" className="alert">
                {errors.password}
              </p>
            )}
          </div>
          <div className="form-group mt-1">
            <input
              type="password"
              name="confirmpassword"
              className="form-control mt-1"
              placeholder="Confirm password"
              onChange={handleChange}
              value={data.confirmpassword}
            />
            {errors.confirmpassword && (
              <p id="alert" className="alert">
                {errors.confirmpassword}
              </p>
            )}
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="link text-right mt-2">
            Already Have An Account? <a href="/login">Login</a>
          </p>
        </div>
      </form>
    </div>
  )
}

export default SignUp
