import React from "react";
import Navbar from "../Components/Navbar";
import { Sidebar } from "../Components/Sidebar.tsx";
import Fault from "../Components/Fault";

const Faults = () => {
  return (
    <div className="home">
      <Sidebar />
      <Navbar />
      <div>
        {/* Set maximum height and overflow for the Fault component */}
        <div>
          <Fault />
        </div>
      </div>
    </div>
  );
};

export default Faults;
