import React from "react";
import Navbar from "../Components/Navbar";
import { Sidebar } from "../Components/Sidebar.tsx";
import Charts from "../Components/Charts";
// import HomeGrid from "../Components/Grid";

const Curves = () => {
  return (
    <div>
      <Sidebar />
      <Navbar />
      <Charts />
    </div>
  );
};

export default Curves;
