import React, { useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Cors/login";
import SignUp from "./Cors/signup";
import Home from "./Cors/home";
import Requests from "./Cors/requests";
import PrivateRoute from "./Cors/privateroute";
import Wait from "./Cors/wait";
import Verification from "./Cors/verificationpage";
import ResetPassword from "./Cors/reset-password";
import NewPassword from "./Cors/new-password";
import Plant from "./Cors/plant";
import CreatePlant from "./Cors/createplant";
import CreateDevice from "./Cors/createdevice";
import Curves from "./Cors/curves";
import Fault from "./Cors/fault";
import Overview from "./Cors/overview";
import Folder from "./Cors/folder";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/requests" element={<Requests />} />
        <Route path="/reset-password/request" element={<NewPassword />} />
        <Route path="/signup" element={<SignUp id="signup" />} />
        <Route path="/wait" element={<Wait />} />
        <Route path="/curves/:plant_id" element={<Curves />} />
        <Route path="/overview/:plant_id" element={<Overview />} />
        <Route path="/fault/:plant_id" element={<Fault />} />
        <Route path="/files" element={<Folder />} />
        <Route path="/plant/:plant_id" element={<Plant />} />
        <Route path="/create-plant" element={<CreatePlant />} />
        <Route path="/create-device/:plant_id" element={<CreateDevice />} />
        <Route exact path="/home" element={<PrivateRoute />}>
          <Route exact path="/home" element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
