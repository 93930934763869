import React, { useState, useEffect } from "react";
import { DatePicker, Select, Space } from "antd";
import dayjs from "dayjs";
const { Option } = Select;
const { RangePicker } = DatePicker;

const DateRange = ({ onDateRangeChange, onUpdateSelectedTimeInterval }) => {
  const [type, setType] = useState("day");
  const [selectedTimeInterval, setSelectedTimeInterval] = useState("15");
  const [rangePickerKey, setRangePickerKey] = useState(0);

  const handleRangePickerChange = (dates, dateStrings) => {
    if (Array.isArray(dates) && dates.length > 0) {
      onDateRangeChange(dates, dateStrings);
    }
  };

  const handleSecondSelectChange = (value) => {
    setSelectedTimeInterval(value);
  };

  const customYearStartFormat = (value) => {
    const year = dayjs(value).year();
    return dayjs(value)
      .year(year)
      .startOf("year")
      .format("YYYY-MM-DD HH:mm:ss");
  };

  // const customYearEndFormat = (value) => {
  //   const year = dayjs(value).year();
  //   return dayjs(value).year(year).endOf("year").format("YYYY-MM-DD HH:mm:ss");
  // };

  useEffect(() => {
    // Set default time interval based on the selected type
    switch (type) {
      case "week":
      case "day":
        setSelectedTimeInterval("15");
        break;
      case "month":
        setSelectedTimeInterval("30");
        break;
      case "year":
        setSelectedTimeInterval("1-month");
        break;
      default:
        setSelectedTimeInterval("15");
    }

    // Clear selected dates when the type changes
    onDateRangeChange([], []);

    // Change the key to reset the RangePicker
    setRangePickerKey((prevKey) => prevKey + 1);
  }, [type]);

  useEffect(() => {
    // Notify the parent about the change
    onUpdateSelectedTimeInterval(selectedTimeInterval);
  }, [selectedTimeInterval]);

  const renderSecondSelect = () => {
    switch (type) {
      case "week":
        return (
          <Select
            value={selectedTimeInterval}
            onChange={handleSecondSelectChange}
            style={{ width: 100 }}
          >
            <Option value="15">15 min</Option>
            <Option value="30">30 min</Option>
            <Option value="60">60 min</Option>
            <Option value="1-day">1 day</Option>
          </Select>
        );
      case "day":
        return (
          <Select
            value={selectedTimeInterval}
            onChange={handleSecondSelectChange}
            style={{ width: 100 }}
          >
            <Option value="15">15 min</Option>
            <Option value="30">30 min</Option>
            <Option value="60">60 min</Option>
          </Select>
        );
      case "month":
        return (
          <Select
            value={selectedTimeInterval}
            onChange={handleSecondSelectChange}
            style={{ width: 100 }}
          >
            <Option value="30">30 min</Option>
            <Option value="60">60 min</Option>
            <Option value="1-day">1 day</Option>
          </Select>
        );
      case "year":
        return (
          <Select
            value={selectedTimeInterval}
            onChange={handleSecondSelectChange}
            style={{ width: 100 }}
          >
            <Option value="1-month">1 month</Option>
          </Select>
        );
      default:
        return null;
    }
  };

  return (
    <Space direction="horizontal" size={20}>
      <Select value={type} onChange={setType} style={{ width: "110%" }}>
        <Option value="day">Day</Option>
        <Option value="week">Week</Option>
        <Option value="month">Month</Option>
        <Option value="year">Year</Option>
      </Select>
      <RangePicker
        key={rangePickerKey} // Change the key to reset the RangePicker
        onChange={handleRangePickerChange}
        format={type === "year" ? customYearStartFormat : "YYYY-MM-DD HH:mm:ss"}
        showTime={type === "date"}
        picker={type}
      />

      {renderSecondSelect()}
    </Space>
  );
};

export default DateRange;
