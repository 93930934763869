import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useParams from react-router-dom
import { DatePicker } from "antd";
import { decrypt } from "n-krypta";
import "./fault.css";

const Fault = () => {
  const { plant_id } = useParams(); // Extract plant_id from the URL
  const history = useNavigate(); // Get the history object for navigation

  const [faultData, setFaultData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [hasAccess, setHasAccess] = useState(true); // Default to true until proven otherwise
  const [csvData, setCsvData] = useState(null);

  // Function to convert data to CSV format
  const convertToCSV = (data) => {
    const header = [
      "Fault Type",
      "Fault Code",
      "Device Name",
      "Device Model",
      "Occurrence Time",
      "Recovery Time",
    ];
    const rows = data.map((row) => [
      row.fault_type,
      row.fault_code,
      row.device_name,
      row.device_model,
      row.occurrence_time,
      row.recovery_time,
    ]);

    const csvContent = [
      header.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");
    return csvContent;
  };

  // Function to handle download button click
  const handleDownloadClick = () => {
    const csvContent = convertToCSV(filteredFaultData);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "filtered_fault_data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    const encryptedUserInfo = localStorage.getItem("LOGIN-INFO");
    const decryptedUserInfo = decrypt(encryptedUserInfo, "mantrac1234");

    const { id, role } = decryptedUserInfo;
    console.log(role);

    console.log(id);

    // Check if the user is not an admin
    if (role !== "Admin") {
      fetch(
        `https://manconnect.net/mantrac-php/user-check.php?id=${id}&plant_id=${plant_id}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Check if the user has access to the specified plant
          const hasAccess = data.plants.some(
            (userPlant) => userPlant.plant_id === Number(plant_id)
          );
          setHasAccess(hasAccess);

          if (!hasAccess) {
            // Redirect the user to the home page if they don't have access
            history("/home");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Handle the error if needed
        });
    }

    console.log(hasAccess);

    // Continue with your logic for both admin and non-admin users
    fetch(`https://manconnect.net/mantrac-php/fault.php?plant_id=${plant_id}`)
      .then((response) => response.json())
      .then((data) => setFaultData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [plant_id, history]);

  const handleMonthChange = (date, dateString) => {
    // Update the state with the selected month
    setSelectedMonth(dateString);
  };

  // Filter fault data based on the selected month
  const filteredFaultData = selectedMonth
    ? faultData.filter((row) => {
        // Assuming the 'occurrence_time' field is in the format 'YYYY-MM-DD'
        const rowMonth = row.occurrence_time.slice(0, 7); // Extract YYYY-MM from the occurrence_time
        return rowMonth === selectedMonth;
      })
    : [];

  if (!hasAccess) {
    return null; // Do not render anything if the user doesn't have access
  }

  return (
    <div className="fault-page">
      <div className="month-picker">
        <DatePicker
          picker="month"
          className="picker"
          onChange={handleMonthChange}
        />
      </div>
      <div className="download-button">
        <button onClick={handleDownloadClick}>
          <img alt="" src="/images/download.svg" />
        </button>
      </div>
      <div className="fault-container">
        <div className="fault">
          {filteredFaultData.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Fault Type</th>
                  <th>Fault Code</th>
                  <th>Device Name</th>
                  <th>Device Model</th>
                  <th>Occurrence Time</th>
                  <th>Recovery Time</th>
                </tr>
              </thead>
              <tbody className="data-body">
                {filteredFaultData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.fault_type}</td>
                    <td>{row.fault_code}</td>
                    <td>{row.device_name}</td>
                    <td>{row.device_model}</td>
                    <td>{row.occurrence_time}</td>
                    <td>{row.recovery_time}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="nodata-container">
              <img alt="" src="/images/nodata.svg" />
              <p className="nodata-message">No Data</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Fault;
