import React from "react";
import Navbar from "../Components/Navbar";
import Circular from "../Components/Circle";
import { Sidebar } from "../Components/Sidebar.tsx";
import HomeGrid from "../Components/Grid";

const Overview = () => {
  return (
    <div className="home">
      <Sidebar />
      <Circular />
      <Navbar />
      {/* <HomeGrid /> */}
    </div>
  );
};

export default Overview;
