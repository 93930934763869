import './verification.css'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'

const passwordRegex = /^([a-zA-Z0-9@*#]{8,})$/

const NewPassword = () => {
  const navigate = useNavigate()
  const [data, setData] = useState({
    password: '',
    confirmpassword: '',
  })
  const [errors, setErrors] = useState({})

  const urlParams = new URLSearchParams(window.location.search)
  const email = urlParams.get('email')

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const validate = () => {
    const newErrors = {}

    if (data.password.length === 0) {
      newErrors.password = 'Password is required'
    } else if (!passwordRegex.test(data.password)) {
      newErrors.password = 'Password Should Be At least 8 Charchters'
    } else if (data.confirmpassword.length === 0) {
      newErrors.confirmpassword = 'Confirm Password is required'
    } else if (data.password !== data.confirmpassword) {
      newErrors.confirmpassword = 'Password Mismatch'
    }

    return newErrors
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const newErrors = validate()
    setErrors(newErrors)
    if (Object.keys(newErrors).length === 0) {
      const password = data.password

      axios
        .post('https://manconnect.net/mantrac-php/new-password.php', {
          email,
          password,
        })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire(
              'Congratulations!',
              'Your Password Have Changed',
              'success',
            )
            navigate('/login')
          } else {
            Swal.fire('Oops...', 'Verification failed', 'error')
          }
        })
        .catch((error) => {
          // Handle error
          Swal.fire('Oops...', 'Something Went Wrong!', 'error')
        })
    }
  }
  return (
    <div className="verification">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <img
            className="form-img"
            src="https://www.mantracgroup.com/media/23rdrnwg/mansour-timeline-2000-2x-1024x768.jpg?width=470"
            alt=""
          ></img>
          <h3 className="form-title">
            <b>Reset Password</b>
          </h3>
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="form-control mt-3"
            onChange={handleChange}
            value={data.password}
          />
          {errors.password && (
            <p id="alert" className="alert">
              {errors.password}
            </p>
          )}
          <input
            type="password"
            name="confirmpassword"
            placeholder="Confirm Password"
            className="form-control mt-3"
            onChange={handleChange}
            value={data.confirmpassword}
          />
          {errors.confirmpassword && (
            <p id="alert" className="alert">
              {errors.confirmpassword}
            </p>
          )}
          <div className="d-grid gap-1 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewPassword
