import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { encrypt } from 'n-krypta'

const Login = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState({
    email: '',
    password: '',
    exist: '',
    states: '',
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const validate = () => {
    const newErrors = {}

    if (user.email.length === 0) {
      newErrors.email = 'Email is required'
    }
    if (user.password.length === 0) {
      newErrors.password = 'Password is required'
    }
    if (user.exist) {
      newErrors.exist = user.exist
    }
    if (user.states) {
      newErrors.states = user.states
    }

    return newErrors
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const newErrors = validate()
    setErrors(newErrors)

    if (Object.keys(newErrors).length === 0) {
      const sendData = {
        email: user.email,
        password: user.password,
      }

      const url = 'https://manconnect.net/mantrac-php/login.php'

      axios.post(url, sendData).then((result) => {
        console.log(result)
        if (result.data.Status === '200') {
          if (result.data.states == 1) {
            const encryptedObject = encrypt(result.data, 'mantrac1234')
            localStorage.setItem('LOGIN-INFO', encryptedObject)
            // console.log(encryptedObject)
            navigate('/home')
          } else {
            setErrors((prevState) => ({
              ...prevState,
              states: 'User Is Not Activated',
            }))
          }
        } else {
          setErrors((prevState) => ({
            ...prevState,
            exist: 'User Does Not Exist',
          }))
        }
      })
    }
  }

  // useEffect(() => {
  //   setErrors(validate())
  // }, [user])

  return (
    <div className="login-form-container">
      <form className="login-form" onSubmit={submitHandler}>
        <div className="login-form-content">
          <img
            className="form-img"
            src="https://www.mantracgroup.com/media/23rdrnwg/mansour-timeline-2000-2x-1024x768.jpg?width=470"
            alt=""
          ></img>
          <h3 className="login-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <input
              type="email"
              name="email"
              className="form-control mt-1"
              placeholder="Email"
              onChange={handleChange}
              value={user.email}
            />
            {errors.email && (
              <p id="alert" className="alert">
                {errors.email}
              </p>
            )}
            {errors.exist && (
              <p id="alert" className="alert">
                {errors.exist}
              </p>
            )}
          </div>
          <div className="form-group mt-3">
            <input
              type="password"
              name="password"
              className="form-control mt-1"
              placeholder="Password"
              onChange={handleChange}
              value={user.password}
            />
            {errors.password && (
              <p id="alert" className="alert">
                {errors.password}
              </p>
            )}
            {errors.states && (
              <p id="alert" className="alert">
                {errors.states}
              </p>
            )}
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="link text-right mt-2">
            Don't Have An Account? <a href="/signup">Sign Up</a>
          </p>
          <div className="forgot-password">
            <a className="link text-right" href="/reset-password">
              Forgot Your Password?
            </a>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login
