import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./plantform.css";
import axios from "axios";
import Swal from "sweetalert2";

export const DeviceForm = () => {
  const { plant_id } = useParams();
  console.log(plant_id);
  const [deviceName, setdeviceName] = useState("");
  const [deviceType, setdeviceType] = useState("");
  const [deviceBrand, setdeviceBrand] = useState("");
  const [deviceModel, setdeviceModel] = useState("");
  const [deviceSerialNumber, setdeviceSerialNumber] = useState("");
  const [deviceRating, setdeviceRating] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);
  const [additionalInputGroups, setAdditionalInputGroups] = useState([]);

  // Add error states for each input
  const [deviceNameError, setdeviceNameError] = useState("");
  const [deviceTypeError, setdeviceTypeError] = useState("");
  const [deviceBrandError, setdeviceBrandError] = useState("");
  const [deviceModelError, setdeviceModelError] = useState("");
  const [deviceSerialNumberError, setdeviceSerialNumberError] = useState("");
  const [deviceRatingError, setdeviceRatingError] = useState("");
  const [parameterErrors, setParameterErrors] = useState([]);
  const [typeErrors, setTypeErrors] = useState([]);

  const handleInputFocus = (fieldName) => {
    setFocusedInput(fieldName);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };

  const handleAddInputGroup = () => {
    setAdditionalInputGroups((prevGroups) => [
      ...prevGroups,
      { parameter: "", unit: "", Type: "" },
    ]);

    setParameterErrors((prevErrors) => [...prevErrors, ""]);
    setTypeErrors((prevErrors) => [...prevErrors, ""]);
  };

  const handleInputChange = (index, field, value) => {
    setAdditionalInputGroups((prevGroups) => {
      const newGroups = [...prevGroups];
      newGroups[index][field] = value;
      return newGroups;
    });

    if (field === "parameter") {
      setParameterErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    } else if (field === "Type") {
      setTypeErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    }
  };

  const handleRemoveInputGroup = (index) => {
    setAdditionalInputGroups((prevGroups) => {
      const newGroups = [...prevGroups];
      newGroups.splice(index, 1);
      return newGroups;
    });

    setParameterErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors.splice(index, 1);
      return newErrors;
    });

    setTypeErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors.splice(index, 1);
      return newErrors;
    });
  };

  const handleSubmit = () => {
    // Validate input fields
    let isValid = true;

    if (!deviceName) {
      setdeviceNameError("Device Name is required");
      isValid = false;
    } else {
      setdeviceNameError("");
    }

    if (!deviceType) {
      setdeviceTypeError("Device Type is required");
      isValid = false;
    } else {
      setdeviceTypeError("");
    }

    if (!deviceBrand) {
      setdeviceBrandError("Device Brand is required");
      isValid = false;
    } else {
      setdeviceBrandError("");
    }

    if (!deviceModel) {
      setdeviceModelError("Device Model is required");
      isValid = false;
    } else {
      setdeviceModelError("");
    }

    if (!deviceSerialNumber) {
      setdeviceSerialNumberError("Device Serial Number is required");
      isValid = false;
    } else {
      setdeviceSerialNumberError("");
    }

    if (!deviceRating) {
      setdeviceRatingError("Device Rating is required");
      isValid = false;
    } else {
      setdeviceRatingError("");
    }

    const parameterErrorsExist = additionalInputGroups.some(
      (group, index) =>
        !group.parameter ||
        (group.parameter &&
          additionalInputGroups
            .slice(0, index)
            .some((prevGroup) => prevGroup.parameter === group.parameter))
    );

    if (parameterErrorsExist) {
      setParameterErrors(
        additionalInputGroups.map((group, index) =>
          !group.parameter
            ? "Parameter is required"
            : group.parameter &&
              additionalInputGroups
                .slice(0, index)
                .some((prevGroup) => prevGroup.parameter === group.parameter)
            ? "Duplicate Parameter"
            : ""
        )
      );
      isValid = false;
    } else {
      // Reset parameter error state if no empty or duplicate parameters
      setParameterErrors(additionalInputGroups.map(() => ""));
    }

    const typeErrorsExist = additionalInputGroups.some(
      (group, index) => !group.Type
    );

    if (typeErrorsExist) {
      setTypeErrors(
        additionalInputGroups.map((group, index) =>
          !group.Type ? "Type is required" : ""
        )
      );

      isValid = false;
    } else {
      // Reset type error state if no empty types
      setTypeErrors(additionalInputGroups.map(() => ""));
    }

    if (!isValid) {
      console.log("Additional Input Groups:", additionalInputGroups);
      return; // Don't submit if there are validation errors
    }

    // Create an object containing the form data
    const formData = {
      plantId: plant_id,
      deviceName: deviceName,
      deviceType: deviceType,
      deviceBrand: deviceBrand,
      deviceModel: deviceModel,
      deviceSerialNumber: deviceSerialNumber,
      deviceRating: deviceRating,
      additionalInputGroups: additionalInputGroups.map(
        ({ parameter, unit, Type }) => ({
          parameter: `${parameter}(${unit})`,
          Type: Type,
        })
      ),
    };

    const sendData = JSON.stringify(formData);

    console.log(sendData);

    // Send the formData object to your backend API using Axios
    axios
      .post("https://manconnect.net/mantrac-php/create-device.php", sendData)
      .then((response) => {
        if (response.status === 202) {
          Swal.fire("Oops...", "Device Already Exists", "error");
        } else if (response.status === 200) {
          Swal.fire(
            "Good Job!",
            "Device Has Been Successfully Created",
            "success"
          );
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error(error);
      });
  };

  return (
    <div className="frame-container">
      <div className="frame-title">Basic Device Information</div>
      <div className="card-title-line"></div>
      <div className="input-group">
        <div className="frame">
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "deviceName" || deviceName !== ""
                  ? "focused"
                  : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "deviceName" ? "" : "Device Name"
                        }
                        value={deviceName}
                        onChange={(e) => {
                          setdeviceName(e.target.value);
                          setdeviceNameError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("deviceName")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="device-name">
                        Device Name <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {deviceNameError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {deviceNameError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "deviceType" || deviceType !== ""
                  ? "focused"
                  : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <select
                        className="real-input"
                        value={deviceType}
                        onChange={(e) => {
                          setdeviceType(e.target.value);
                          setdeviceTypeError(""); // Clear the error when selecting an option
                        }}
                        onFocus={() => handleInputFocus("deviceType")}
                        onBlur={handleInputBlur}
                      >
                        <option value="" disabled>
                          Select Device Type
                        </option>
                        <option value="Inverter">Inverter</option>
                        <option value="Generator">Generator</option>
                        <option value="Sensor">Sensor</option>
                        <option value="Meter">Meter</option>
                      </select>
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="device-type">
                        Device Type <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {deviceTypeError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {deviceTypeError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="input-group">
        <div className="frame">
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "deviceBrand" || deviceBrand !== ""
                  ? "focused"
                  : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "deviceBrand" ? "" : "Device Brand"
                        }
                        value={deviceBrand}
                        onChange={(e) => {
                          setdeviceBrand(e.target.value);
                          setdeviceBrandError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("deviceBrand")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="text">
                        Device Brand <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {deviceBrandError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {deviceBrandError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "deviceModel" || deviceModel !== ""
                  ? "focused"
                  : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "deviceModel" ? "" : "Device Model"
                        }
                        value={deviceModel}
                        onChange={(e) => {
                          setdeviceModel(e.target.value);
                          setdeviceModelError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("deviceModel")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="text">
                        Device Model <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {deviceModelError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {deviceModelError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="input-group">
        <div className="frame">
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "deviceSerialNumber" ||
                deviceSerialNumber !== ""
                  ? "focused"
                  : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "deviceSerialNumber"
                            ? ""
                            : "Device Serial Number"
                        }
                        value={deviceSerialNumber}
                        onChange={(e) => {
                          setdeviceSerialNumber(e.target.value);
                          setdeviceSerialNumberError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("deviceSerialNumber")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="device-serial">
                        Device Serial Number{" "}
                        <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {deviceSerialNumberError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {deviceSerialNumberError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input">
            <div
              className={`input-title-section ${
                focusedInput === "deviceRating" || deviceRating !== ""
                  ? "focused"
                  : ""
              }`}
            >
              <div className="input-section">
                <div className="overlap-group">
                  <div className="input-items-group-wrapper">
                    <div className="input-items-group">
                      <input
                        className="real-input"
                        type="text"
                        placeholder={
                          focusedInput === "deviceRating" ? "" : "Device Rating"
                        }
                        value={deviceRating}
                        onChange={(e) => {
                          setdeviceRating(e.target.value);
                          setdeviceRatingError(""); // Clear the error when typing
                        }}
                        onFocus={() => handleInputFocus("deviceRating")}
                        onBlur={handleInputBlur}
                      />
                    </div>
                  </div>
                  <div className="mini-title-container">
                    <div className="title">
                      <div className="device-rating">
                        Device Rating <span className="text-wrapper">*</span>
                      </div>
                      {/* <div className="line"></div> */}
                    </div>
                  </div>
                </div>
                {deviceRatingError && (
                  <div className="error-message">
                    <img alt="" src="/images/error.svg" className="error-img" />
                    {deviceRatingError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {additionalInputGroups.map((group, index) => (
        <div key={index} className="input-group">
          <div className="frame">
            {/* Parameter Input */}
            <div className="input">
              <div
                className={`input-title-section ${
                  focusedInput === `parameter_${index}` ||
                  group.parameter !== ""
                    ? "focused"
                    : ""
                }`}
              >
                <div className="input-section">
                  <div className="overlap-group">
                    <div className="input-items-group-wrapper">
                      <div className="input-items-group">
                        <input
                          className="real-input"
                          type="text"
                          placeholder={
                            focusedInput === `parameter_${index}`
                              ? ""
                              : "Parameter"
                          }
                          value={group.parameter}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "parameter",
                              e.target.value
                            )
                          }
                          onFocus={() => handleInputFocus(`parameter_${index}`)}
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div>
                    <div className="mini-title-container">
                      <div className="title">
                        <div className="text">
                          Parameter <span className="text-wrapper">*</span>
                        </div>
                        {/* <div className="line"></div> */}
                      </div>
                    </div>
                  </div>
                  {parameterErrors[index] && (
                    <div className="error-message">
                      <img
                        alt=""
                        src="/images/error.svg"
                        className="error-img"
                      />
                      {parameterErrors[index]}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Unit Input */}
            <div className="input">
              <div
                className={`input-title-section ${
                  focusedInput === `unit_${index}` || group.unit !== ""
                    ? "focused"
                    : ""
                }`}
              >
                <div className="input-section">
                  <div className="overlap-group">
                    <div className="input-items-group-wrapper">
                      <div className="input-items-group">
                        <input
                          className="real-input"
                          type="text"
                          placeholder={
                            focusedInput === `unit_${index}` ? "" : "Unit"
                          }
                          value={group.unit}
                          onChange={(e) =>
                            handleInputChange(index, "unit", e.target.value)
                          }
                          onFocus={() => handleInputFocus(`unit_${index}`)}
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div>
                    <div className="mini-title-container">
                      <div className="title">
                        <div className="text">
                          Unit <span className="text-wrapper">*</span>
                        </div>
                        {/* <div className="line"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Dropdown Input */}
            <div className="input">
              <div
                className={`input-title-section ${
                  focusedInput === `Type_${index}` ? "focused" : ""
                }`}
              >
                <div className="input-section">
                  <div className="overlap-group">
                    <div className="input-items-group-wrapper">
                      <div className="input-items-group">
                        {/* Replace this with your dropdown component */}
                        <select
                          className="real-input"
                          value={group.Type || ""}
                          onChange={(e) => {
                            handleInputChange(index, "Type", e.target.value);
                            setTypeErrors("");
                          }}
                          onFocus={() => handleInputFocus(`Type_${index}`)}
                          onBlur={handleInputBlur}
                        >
                          <option value="" disabled>
                            Parameter Type
                          </option>
                          <option value="decimal">Decimal</option>
                          <option value="text">Text</option>
                          <option value="boolean">Boolean</option>
                          {/* Add more options as needed */}
                        </select>
                      </div>
                    </div>
                    <div className="mini-title-container">
                      <div className="title">
                        <div className="text">
                          Parameter Type <span className="text-wrapper">*</span>
                        </div>
                        {/* <div className="line"></div> */}
                      </div>
                    </div>
                  </div>
                  {typeErrors[index] && (
                    <div className="error-message">
                      <img
                        alt=""
                        src="/images/error.svg"
                        className="error-img"
                      />
                      {typeErrors[index]}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <button
              onClick={() => handleRemoveInputGroup(index)}
              className="remove-button"
            >
              <img alt="" src="/images/cancel.svg" />
            </button>
          </div>
        </div>
      ))}

      {/* Button to Add Input Group */}
      <button
        onClick={handleAddInputGroup}
        className={`add-button ${
          !parameterErrors[parameterErrors.length - 1] &&
          additionalInputGroups.length > 0
            ? ""
            : parameterErrors
            ? "with-error"
            : ""
        }`}
      >
        <img alt="" src="/images/add-circle.svg" />
        Add Parameter
      </button>

      <button className="save-device-button" onClick={handleSubmit}>
        Create Device
      </button>
    </div>
  );
};
