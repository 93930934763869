import React, { useEffect, useState } from "react";
import axios from "axios";
import "./charts.css";
import EChart from "./echarts";
import DateRange from "./DatePicker";
import { Switch } from "antd";
import { CircularProgress } from "@mui/material";
import "rsuite/dist/rsuite-no-reset.min.css";
import DataTable from "./DataTable";
import { decrypt } from "n-krypta";
import { useParams } from "react-router-dom";
// import { color } from "echarts";

const Charts = () => {
  const [plantNames, setPlantNames] = useState([]);
  const [selectedPlantId, setSelectedPlantId] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [seriesVisibility, setSeriesVisibility] = useState({});
  const [sectionVisibility, setSectionVisibility] = useState({});
  const [deviceVisibility, setDeviceVisibility] = useState({});
  const [deviceDetails, setDeviceDetails] = useState({});
  const [deviceCheckboxVisibility, setDeviceCheckboxVisibility] = useState({});
  const [plantDetails, setPlantDetails] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedPlantName, setSelectedPlantName] = useState("");
  const [selectedDeviceKey, setSelectedDeviceKey] = useState(null);
  const [seriesColors, setSeriesColors] = useState([]);
  const [seriesTypes, setSeriesTypes] = useState({});
  const [selectedButton, setSelectedButton] = useState("Day");
  const [isPickerActive, setIsPickerActive] = useState(false);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState([]);
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newselectedPlantId, setNewSelectedPlantId] = useState(null);
  const [newselectedPlantName, setNewSelectedPlantName] = useState("");
  const [newselectedDeviceKey, setNewSelectedDeviceKey] = useState(null);
  const [newdeviceType, setNewDeviceType] = useState("");
  const [newdeviceIndex, setNewDeviceIndex] = useState(null);
  const [newdevice, setNewDevice] = useState(null);
  const [selectedFieldNamess, setSelectedFieldNamess] = useState("");
  const [prevLength, setPrevLength] = useState(0);

  const { plant_id } = useParams();

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
  };

  const downloadCSV = () => {
    if (filteredChartData.length === 0 || selectedCheckboxes.length === 0) {
      console.error("No data or no checkbox selected.");
      return;
    }

    // Extract selected field names from the selected checkboxes
    const selectedFieldNames = selectedCheckboxes
      .filter((checkbox) => checkbox.selectedFieldName)
      .map((checkbox) => checkbox.selectedFieldName);

    const getUniqueKey = (fieldName) => {
      for (const item of filteredChartData) {
        if (item[fieldName] !== undefined) {
          return item.uniqueKey || "uniqueKey";
        }
      }
      return "uniqueKey";
    };

    // Include Time in the header
    const header = [
      "Time",
      ...selectedFieldNames.map(
        (fieldName) => `${fieldName}(${getUniqueKey(fieldName)})`
      ),
    ];

    // Group data by Time within the specified range
    const groupedData = {};
    filteredChartData.forEach((row) => {
      const time = row.Time;
      if (time >= formattedStartDate && time <= formattedEndDate) {
        if (!groupedData[time]) {
          groupedData[time] = {};
        }
        selectedFieldNames.forEach((fieldName) => {
          if (!groupedData[time][fieldName]) {
            groupedData[time][fieldName] = row[fieldName];
          }
        });
      }
    });

    // Convert groupedData to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [header.join(",")]
        .concat(
          Object.entries(groupedData).map(([time, rowData]) => {
            const values = [time];
            selectedFieldNames.forEach((fieldName) => {
              values.push(
                rowData[fieldName] !== undefined ? rowData[fieldName] : ""
              );
            });
            return values.join(",");
          })
        )
        .join("\n");

    // Create a data URI and trigger a download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "filteredChartData.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getType = (seriesName) => {
    // Check if seriesName includes "Energy"ç
    if (seriesName.includes("(kWh)")) {
      return "bar";
    }

    // Default to "line" if the seriesName doesn't include "Energy"
    return "line";
  };

  const handleDateRangeChange = (
    dates,
    dateStrings,
    selectedTimeInterval,
    selectedPlantId
  ) => {
    // When DateRangePicker value changes, extract start and end dates
    if (Array.isArray(dates) && dates.length === 2) {
      const [startDate, endDate] = dateStrings;

      // if (selectedCheckboxes.length > 0) {
      //   const lastSelectedCheckbox =
      //     selectedCheckboxes[selectedCheckboxes.length - 1];

      //   console.log(
      //     "lastSelectedCheckbox",
      //     lastSelectedCheckbox.selectedDeviceKey
      //   );

      //   if (!lastSelectedCheckbox.selectedDeviceKey) {
      //     fetchPlantDetails(
      //       newselectedPlantId,
      //       newselectedPlantName,
      //       startDate,
      //       endDate
      //     );
      //   } else {
      //     fetchDeviceDetails(
      //       newselectedPlantId,
      //       newselectedPlantName,
      //       newdeviceType,
      //       newdeviceIndex,
      //       newdevice
      //     );
      //   }
      // }
      setSelectedDateRange([startDate, endDate]);
      // setSelectedTimeInterval(selectedTimeInterval);
    } else {
      setSelectedDateRange([]);
    }
  };

  const handleSelectedTimeIntervalUpdate = (interval) => {
    // Perform actions with the updated interval
    console.log("Selected Time Interval Updated:", interval);
    // You can do additional logic here based on the updated interval
    setSelectedTimeInterval(interval);
  };

  const generateRandomColor = () => {
    let randomColor;
    do {
      // Generate a random color with higher brightness
      randomColor = `#${Math.floor(Math.random() * 0xaaaaaa + 0x555555)
        .toString(16)
        .padStart(6, "0")}`;
    } while (randomColor === "#000000"); // Avoid black color
    return randomColor;
  };

  const isAnyCheckboxSelected = Object.values(seriesVisibility).some(
    (value) => value
  );

  // Toggle the visibility of a specific data series
  const toggleSeriesVisibility = (seriesName, deviceId = null) => {
    // If the device is visible, do not toggle series visibility
    if (deviceId && deviceVisibility[selectedPlantId]?.[deviceId]) {
      return;
    }

    const color = generateRandomColor();
    const type = getType(seriesName);

    setSeriesColors((prevSeriesColors) => ({
      ...prevSeriesColors,
      [seriesName]: color,
    }));

    setSeriesVisibility((prevSeriesVisibility) => ({
      ...prevSeriesVisibility,
      [seriesName]: !prevSeriesVisibility[seriesName],
    }));

    if (deviceId) {
      setDeviceDetails((prevDeviceDetails) => ({
        ...prevDeviceDetails,
        [selectedPlantId]: {
          ...prevDeviceDetails[selectedPlantId],
          [deviceId]: {
            ...prevDeviceDetails[selectedPlantId]?.[deviceId],
            seriesVisibility: {
              ...prevDeviceDetails[selectedPlantId]?.[deviceId]
                ?.seriesVisibility,
              [seriesName]:
                !prevDeviceDetails[selectedPlantId]?.[deviceId]
                  ?.seriesVisibility?.[seriesName],
            },
          },
        },
      }));

      // Update the selected checkboxes array
      const selectedCheckbox = {
        selectedFieldName: seriesName,
        selectedPlantId: newselectedPlantId,
        selectedPlantName: newselectedPlantName,
        selectedDeviceKey: newselectedDeviceKey,
        color,
        type: getType(seriesName),
      };

      setSelectedCheckboxes((prevSelectedCheckboxes) => {
        if (
          prevSelectedCheckboxes.some(
            (checkbox) => checkbox.selectedFieldName === seriesName
          )
        ) {
          // If the checkbox is already selected, remove it
          return prevSelectedCheckboxes.filter(
            (checkbox) => checkbox.selectedFieldName !== seriesName
          );
        } else {
          // Otherwise, add it
          return [...prevSelectedCheckboxes, selectedCheckbox];
        }
      });

      setSeriesTypes((prevSeriesTypes) => ({
        ...prevSeriesTypes,
        [seriesName]: type,
      }));
    }
  };

  console.log(toggleSeriesVisibility);
  console.log("checkboxxxxx", selectedCheckboxes);

  const toggleSection = (plantId, section) => {
    setSectionVisibility((prevSectionVisibility) => ({
      ...prevSectionVisibility,
      [plantId]: {
        ...prevSectionVisibility[plantId],
        [section]: !prevSectionVisibility[plantId]?.[section],
      },
    }));
  };

  const toggleDeviceVisibility = (plantId, deviceId) => {
    if (!deviceVisibility[plantId]) {
      deviceVisibility[plantId] = {};
    }

    if (!deviceVisibility[plantId][deviceId]) {
      // Clear device details if the section is being expanded
      setDeviceDetails((prevDeviceDetails) => ({
        ...prevDeviceDetails,
        [plantId]: {
          ...prevDeviceDetails[plantId],
          [deviceId]: null,
        },
      }));

      // Initialize the device checkbox visibility state
      setDeviceCheckboxVisibility((prevDeviceCheckboxVisibility) => ({
        ...prevDeviceCheckboxVisibility,
        [plantId]: {
          ...prevDeviceCheckboxVisibility[plantId],
          [deviceId]: false,
        },
      }));
    }

    setDeviceVisibility((prevDeviceVisibility) => ({
      ...prevDeviceVisibility,
      [plantId]: {
        ...prevDeviceVisibility[plantId],
        [deviceId]: !prevDeviceVisibility[plantId]?.[deviceId],
      },
    }));
    console.log(deviceVisibility);
  };

  console.log(selectedDateRange);

  const startDate =
    selectedDateRange[0] ||
    new Date().toISOString().slice(0, 19).replace("T", " ");
  const endDate =
    selectedDateRange[1] ||
    new Date().toISOString().slice(0, 19).replace("T", " ");
  console.log("start:", startDate);

  const formattedStartDate = startDate;

  const formattedEndDate = endDate;

  console.log(formattedStartDate); // Example: "2023-09-12 00:00:00"
  console.log(formattedEndDate); // Example: "2023-09-13 00:00:00"

  useEffect(
    (newselectedPlantId) => {
      // Call fetchPlantDetails with the updated selectedFieldNames
      if (selectedFieldNamess) {
        console.log("fetchPlantDetails");

        fetchPlantDetails(
          newselectedPlantId,
          newselectedPlantName,
          startDate,
          endDate
        );
      } else if (newdeviceType) {
        console.log("fetchPDeviceDetails");
        fetchDeviceDetails(
          newselectedPlantId,
          newselectedPlantName,
          newdeviceType,
          newdeviceIndex,
          newdevice
        );
      }
    },
    [selectedCheckboxes, startDate, endDate]
  );

  useEffect(() => {
    // Update only when a new row is added
    if (selectedCheckboxes.length > prevLength) {
      setSelectedCheckboxes((prevCheckboxes) => {
        const lastIndex = prevCheckboxes.length - 1;

        if (lastIndex >= 0) {
          const updatedCheckboxes = [...prevCheckboxes];
          updatedCheckboxes[lastIndex] = {
            ...updatedCheckboxes[lastIndex],
            selectedPlantName: newselectedPlantName,
            selectedPlantId: newselectedPlantId,
            selectedDeviceKey: newselectedDeviceKey,
          };
          return updatedCheckboxes;
        }

        return prevCheckboxes;
      });

      // Update the previous length
      setPrevLength(selectedCheckboxes.length);
    }
  }, [newselectedPlantName, newselectedDeviceKey]);

  useEffect(() => {
    // Get user information from local storage
    const encryptedUserInfo = localStorage.getItem("LOGIN-INFO");
    const decryptedUserInfo = decrypt(encryptedUserInfo, "mantrac1234");

    // Check if user information is available
    if (decryptedUserInfo && decryptedUserInfo.id && decryptedUserInfo.role) {
      const userId = decryptedUserInfo.id;
      const userRole = decryptedUserInfo.role;

      // Make a GET request to the plant-name.php API with user ID and role parameters
      axios
        .get(
          `https://manconnect.net/mantrac-php/plant-name.php?id=${userId}&role=${userRole}&plant_id=${plant_id}`
        )
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            setPlantNames(response.data);

            // Initialize section visibility
            const initialSectionVisibility = {};
            response.data.forEach((plant) => {
              initialSectionVisibility[plant.plant_id] = {};
            });
            setSectionVisibility(initialSectionVisibility);

            // Initialize deviceDetails state
            setDeviceDetails((prevDeviceDetails) => {
              const newDeviceDetails = { ...prevDeviceDetails };
              response.data.forEach((plant) => {
                newDeviceDetails[plant.plant_id] =
                  newDeviceDetails[plant.plant_id] || {};
              });
              return newDeviceDetails;
            });
          } else {
            console.error("No data received from the server.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      console.error("User information not available.");
    }
  }, []);

  console.log(seriesVisibility);

  const fetchColumnNames = async (
    plantId,
    selectedPlantName,
    deviceType,
    deviceIndex,
    device
  ) => {
    setSelectedPlantName(selectedPlantName);

    let tableName;

    if (plantId && selectedPlantName && deviceType && deviceIndex) {
      tableName = `${selectedPlantName.replace(
        / /g,
        "_"
      )}_${getDeviceAbbreviation(deviceType)}${deviceIndex}_history`;
    } else {
      tableName = `${selectedPlantName.replace(/ /g, "_")}_history`;
    }

    if (
      sectionVisibility[plantId]?.Plant &&
      deviceVisibility[plantId]?.[device.device_id]
    ) {
      return;
    }

    try {
      const response = await axios.get(
        `https://manconnect.net/mantrac-php/column-names.php`,
        {
          params: { table_name: tableName },
        }
      );

      if (response.data && response.data.length > 0) {
        const fieldNames = response.data;

        console.log("fieldNames: ", fieldNames);

        if (plantId && selectedPlantName && deviceType && deviceIndex) {
          const deviceSeriesVisibility = {
            ...deviceDetails[plantId]?.[`${deviceType}-${deviceIndex}`]
              ?.seriesVisibility,
          };
          if (!deviceSeriesVisibility) {
            fieldNames.forEach((fieldName) => {
              deviceSeriesVisibility[fieldName] = true; // Set default visibility to true
            });
          }

          setSeriesVisibility((prevSeriesVisibility) => ({
            ...prevSeriesVisibility,
            ...deviceSeriesVisibility,
          }));

          setDeviceDetails((prevDeviceDetails) => {
            const deviceKey = `${deviceType}-${deviceIndex}`;

            setSelectedDeviceKey(deviceKey);

            return {
              ...prevDeviceDetails,
              [plantId]: {
                ...prevDeviceDetails[plantId],
                [deviceKey]: [
                  ...(prevDeviceDetails[plantId]?.[deviceKey] || []), // Preserve existing data
                  ...response.data.map((item) => {
                    const dataItem = {};
                    fieldNames.forEach((fieldName) => {
                      // Apply the filter here
                      if (
                        ![
                          "Time",
                          "Status",
                          "Communication",
                          "Alarm / Fault",
                        ].includes(fieldName)
                      ) {
                        dataItem[fieldName] = item[fieldName];
                      }
                    });
                    return dataItem;
                  }),
                ],
                seriesVisibility: deviceSeriesVisibility,
              },
            };
          });
        } else {
          const plantSeriesVisibility = {
            ...(plantDetails[plantId]?.seriesVisibility || {}),
          };

          // Initialize the seriesVisibility for this plant if not exists
          if (!plantSeriesVisibility) {
            fieldNames.forEach((fieldName) => {
              plantSeriesVisibility[fieldName] = true; // Set default visibility to true
            });
          }

          // Update seriesVisibility state with the new series names for the plant
          setSeriesVisibility((prevSeriesVisibility) => ({
            ...prevSeriesVisibility,
            ...plantSeriesVisibility,
          }));
          setPlantDetails((prevPlantDetails) => ({
            ...prevPlantDetails,
            [plantId]: {
              ...prevPlantDetails[plantId],
              seriesVisibility: plantSeriesVisibility,
              [plantId]: [
                ...(prevPlantDetails[plantId]?.[plantId] || []), // Preserve existing data
                ...fieldNames.map((item) => {
                  const dataItem = {};
                  fieldNames.forEach((fieldName) => {
                    // Apply the filter here
                    if (
                      ![
                        "Time",
                        "Status",
                        "Communication",
                        "Alarm / Fault",
                      ].includes(fieldName)
                    ) {
                      dataItem[fieldName] = item[fieldName];
                    }
                  });
                  return dataItem;
                }),
              ],
            },
          }));
        }
      } else {
        console.error("No column names received from the server.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching column names:", error);
      return [];
    }
  };

  const fetchPlantDetails = (
    plantId,
    selectedPlantName,
    startDate,
    endDate
  ) => {
    const selectedFieldNames = selectedCheckboxes
      .filter(
        (item) =>
          item.selectedPlantId ===
            selectedCheckboxes[selectedCheckboxes.length - 1]
              ?.selectedPlantId &&
          item.selectedDeviceKey ===
            selectedCheckboxes[selectedCheckboxes.length - 1]?.selectedDeviceKey
      )
      .map((item) => item.selectedFieldName);

    console.log("selectedfieldNames", selectedFieldNames);
    setIsLoading(true);
    setSelectedPlantName(selectedPlantName);
    const tableName = `${selectedPlantName.replace(/ /g, "_")}_history`;

    // Check if the "chevron-right" is clicked
    if (sectionVisibility[plantId]?.Plant) {
      setIsLoading(false);
      return;
    }

    axios
      .get(`https://manconnect.net/mantrac-php/table-name.php`, {
        params: {
          table_name: tableName,
          start_date: startDate, // Include start date
          end_date: endDate, // Include end date
          selected_field_names: selectedFieldNames,
        },
      })
      .then((response) => {
        if (response.data && response.data[0]) {
          const fieldNames = Object.keys(response.data[0]);

          // Create a map of seriesVisibility for this plant
          const plantSeriesVisibility = {
            ...(plantDetails[plantId]?.seriesVisibility || {}),
          };

          // Initialize the seriesVisibility for this plant if not exists
          if (!plantSeriesVisibility) {
            fieldNames.forEach((fieldName) => {
              plantSeriesVisibility[fieldName] = true; // Set default visibility to true
            });
          }

          // Update seriesVisibility state with the new series names for the plant
          setSeriesVisibility((prevSeriesVisibility) => ({
            ...prevSeriesVisibility,
            ...plantSeriesVisibility,
          }));

          // Filter out duplicate data based on both name and value properties
          const newData = response.data.filter((item) => {
            return !chartData.some((existingItem) =>
              fieldNames.every(
                (fieldName) => existingItem[fieldName] === item[fieldName]
              )
            );
          });

          // Update chartData state with the new data
          setChartData((prevChartData) => {
            const newData = response.data.map((item) => {
              const uniqueKey = `${selectedPlantName.replace(/\s+/g, "_")}`;
              const existingIndex = prevChartData.findIndex(
                (row) => row.uniqueKey === uniqueKey && row.time === item.time
              );

              if (existingIndex !== -1) {
                // Remove the existing row from prevChartData
                prevChartData.splice(existingIndex, 1);
              }

              // Add the new row to newData
              return {
                ...item,
                uniqueKey,
              };
            });

            return [...prevChartData, ...newData];
          });

          // Update plantDetails state with the new data
          setPlantDetails((prevPlantDetails) => ({
            ...prevPlantDetails,
            [plantId]: {
              ...prevPlantDetails[plantId],
              seriesVisibility: plantSeriesVisibility,
              [plantId]: [
                ...(prevPlantDetails[plantId]?.[plantId] || []), // Preserve existing data
                ...newData.map((item) => {
                  const dataItem = {};
                  fieldNames.forEach((fieldName) => {
                    // Apply the filter here
                    if (
                      ![
                        "Time",
                        "Status",
                        "Communication",
                        "Alarm / Fault",
                      ].includes(fieldName)
                    ) {
                      dataItem[fieldName] = item[fieldName];
                    }
                  });
                  return dataItem;
                }),
              ],
            },
          }));
        } else {
          console.error("No plant details received from the server.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching plant details:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedPlantId) {
      // Fetch devices data for the selected plant
      axios
        .get(
          `https://manconnect.net/mantrac-php/device-data.php?plant_id=${selectedPlantId}`
        )
        .then((response) => {
          if (response.data) {
            // Process the device data here
            const deviceTypeCounters = {
              Inverter: 0,
              Generator: 0,
              Sensor: 0,
              Meter: 0,
            };
            const updatedDeviceData = response.data.map((device) => {
              // Increment the counter for the specific device type
              deviceTypeCounters[device.device_type]++;
              // Calculate the index of the device within its type
              const deviceIndex = deviceTypeCounters[device.device_type];
              return {
                ...device,
                deviceIndex: deviceIndex,
              };
            });

            // Update device data for the selected plant
            setDeviceData((prevDeviceData) => ({
              ...prevDeviceData,
              [selectedPlantId]: updatedDeviceData,
            }));
          } else {
            console.error("No device data received from the server.");
          }
        })
        .catch((error) => {
          console.error("Error fetching device data:", error);
        });
    }
  }, [selectedPlantId]);

  useEffect(() => {
    // Filter data based on current device and series
    let filteredData;

    switch (selectedTimeInterval) {
      case "15":
        filteredData = chartData;
        break;
      case "30":
        filteredData = chartData.filter((item, index) => index % 2 === 0);
        break;
      case "60":
        filteredData = chartData.filter((item, index) => index % 4 === 0);
        break;
      case "1-day":
        filteredData = chartData
          .map((item, index, array) => {
            const currentDate = item.Time.split(" ")[0];
            const newItem = { ...item, Time: currentDate };
            const currentUniqueKey = newItem.uniqueKey;

            const nextDate =
              index < array.length - 1
                ? array[index + 1].Time.split(" ")[0]
                : null;

            const nextUniqueKey = nextDate ? array[index + 1].uniqueKey : null;

            if (currentDate === nextDate) {
              if (currentUniqueKey !== nextUniqueKey) {
                return newItem;
              }
            } else {
              return newItem;
            }

            return null; // Skip items that don't meet the condition
          })
          .filter(Boolean); // Remove null values from the result
        break;
      case "1-month":
        filteredData = chartData
          .map((item, index, array) => {
            const currentDate = item.Time.split(" ")[0];
            const currentYearMonth = currentDate.slice(0, 7); // Extract year and month in "yyyy-mm" format
            console.log("currentYearMonth", currentYearMonth);
            const newItem = { ...item, Time: currentYearMonth };
            const currentUniqueKey = newItem.uniqueKey;

            const nextDate =
              index < array.length - 1
                ? array[index + 1].Time.split(" ")[0]
                : null;

            const nextYearMonth = nextDate ? nextDate.slice(0, 7) : null;

            const nextUniqueKey = nextYearMonth
              ? array[index + 1].uniqueKey
              : null;

            if (currentYearMonth === nextYearMonth) {
              if (currentUniqueKey !== nextUniqueKey) {
                return newItem;
              }
            } else {
              return newItem;
            }

            return null; // Skip items that don't meet the condition
          })
          .filter(Boolean); // Remove null values from the result
        break;
      default:
        filteredData = chartData;
    }

    // Update the state with the filtered data
    setFilteredChartData(filteredData);

    console.log("filteredChartData:", filteredData);
  }, [selectedTimeInterval, chartData]);

  function getDeviceAbbreviation(deviceType) {
    switch (deviceType) {
      case "Inverter":
        return "INV";
      case "Generator":
        return "GEN";
      case "Sensor":
        return "SEN";
      case "Meter":
        return "MET";
      default:
        return ""; // Handle other cases if needed
    }
  }

  const fetchDeviceDetails = (
    plantId,
    selectedPlantName,
    deviceType,
    deviceIndex,
    device
  ) => {
    setSelectedPlantName(selectedPlantName);
    const tableName = `${selectedPlantName.replace(
      / /g,
      "_"
    )}_${getDeviceAbbreviation(deviceType)}${deviceIndex}_history`;

    console.log("tableName", tableName);

    const selectedFieldNames = selectedCheckboxes
      .filter(
        (item) =>
          item.selectedPlantId ===
            selectedCheckboxes[selectedCheckboxes.length - 1]
              ?.selectedPlantId &&
          item.selectedDeviceKey ===
            selectedCheckboxes[selectedCheckboxes.length - 1]?.selectedDeviceKey
      )
      .map((item) => item.selectedFieldName);

    console.log("selectedfieldNames", selectedFieldNames);
    setIsLoading(true);

    if (deviceVisibility[plantId]?.[device.device_id]) {
      setIsLoading(false);
      return;
    }

    axios
      .get(`https://manconnect.net/mantrac-php/table-name.php`, {
        params: {
          table_name: tableName,
          start_date: startDate, // Include start date
          end_date: endDate, // Include end date
          selected_field_names: selectedFieldNames,
        },
      })
      .then((response) => {
        if (response.data && response.data[0]) {
          console.log(response.data);
          // Use the field names from response.data[0] as the new series names
          const fieldNames = Object.keys(response.data[0]);

          // Create a map of seriesVisibility for this device
          const deviceSeriesVisibility = {
            ...deviceDetails[plantId]?.[`${deviceType}-${deviceIndex}`]
              ?.seriesVisibility,
          };
          if (!deviceSeriesVisibility) {
            fieldNames.forEach((fieldName) => {
              deviceSeriesVisibility[fieldName] = true; // Set default visibility to true
            });
          }

          setSeriesVisibility((prevSeriesVisibility) => ({
            ...prevSeriesVisibility,
            ...deviceSeriesVisibility,
          }));

          // Update seriesVisibility state with the new series names
          const newData = response.data.filter((item) => {
            return !chartData.some((existingItem) =>
              fieldNames.every(
                (fieldName) => existingItem[fieldName] === item[fieldName]
              )
            );
          });

          // Update chartData state with the new data
          setChartData((prevChartData) => {
            const newData = response.data.map((item) => {
              const uniqueKey = `${selectedPlantName.replace(
                /\s+/g,
                "_"
              )}-${deviceType}-${deviceIndex}`;
              const existingIndex = prevChartData.findIndex(
                (row) => row.uniqueKey === uniqueKey && row.time === item.time
              );

              if (existingIndex !== -1) {
                // Remove the existing row from prevChartData
                prevChartData.splice(existingIndex, 1);
              }

              // Add the new row to newData
              return {
                ...item,
                uniqueKey,
              };
            });

            return [...prevChartData, ...newData];
          });

          // Update deviceDetails state with the new data
          setDeviceDetails((prevDeviceDetails) => {
            const deviceKey = `${deviceType}-${deviceIndex}`;

            setSelectedDeviceKey(deviceKey);

            return {
              ...prevDeviceDetails,
              [plantId]: {
                ...prevDeviceDetails[plantId],
                [deviceKey]: [
                  ...(prevDeviceDetails[plantId]?.[deviceKey] || []), // Preserve existing data
                  ...response.data.map((item) => {
                    const dataItem = {};
                    fieldNames.forEach((fieldName) => {
                      // Apply the filter here
                      if (
                        ![
                          "Time",
                          "Status",
                          "Communication",
                          "Alarm / Fault",
                        ].includes(fieldName)
                      ) {
                        dataItem[fieldName] = item[fieldName];
                      }
                    });
                    return dataItem;
                  }),
                ],
                seriesVisibility: deviceSeriesVisibility,
              },
            };
          });
        } else {
          console.error("No device details received from the server.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching device details:", error);
        setIsLoading(false);
      });
  };

  console.log(deviceDetails);
  console.log("plantDetails:", plantDetails);
  console.log("seriesVisibility:", seriesVisibility);
  console.log("checked??: ", isChecked);

  return (
    <div className="thechart">
      <div className="chart-container">
        <div className="bar">
          <div className="chart-line"></div>
          <div className="date-range-picker">
            <DateRange
              onDateRangeChange={handleDateRangeChange}
              onUpdateSelectedTimeInterval={handleSelectedTimeIntervalUpdate} // Pass the function to the child component
            />
          </div>
          <Switch
            className="chart-color"
            defaultChecked={isChecked}
            onChange={handleSwitchChange}
            style={{
              backgroundColor: isChecked ? "grey" : "black",
            }}
          />
          <div className="download-button">
            <button onClick={downloadCSV}>
              <img alt="" src="/images/download.svg" className="download-img" />
            </button>
          </div>
        </div>
        <div className="sidenav">
          {plantNames.map((plant) => (
            <div className="sidenav-item" key={plant.plant_id}>
              <div className="sidenav-section">
                <div
                  className="sidenav-section-header"
                  onClick={() => {
                    toggleSection(plant.plant_id, "main");
                    setSelectedPlantId(plant.plant_id);
                  }}
                >
                  <img
                    src={"/images/square.svg"}
                    alt="Toggle"
                    className="chevron-icon"
                  />
                  {plant.name}
                </div>
                {sectionVisibility[plant.plant_id]?.main && (
                  <div className="sidenav-section-content">
                    <div className="sidenav-item">
                      <div className="sidenav-section">
                        <div
                          className="sidenav-submenu-header"
                          onClick={() => {
                            toggleSection(plant.plant_id, "Plant");
                            fetchColumnNames(plant.plant_id, plant.name);
                            // fetchPlantDetails(
                            // plant.plant_id,
                            // plant.name,
                            // startDate,
                            // endDate
                            // );
                          }}
                        >
                          <img
                            src={
                              sectionVisibility[plant.plant_id]?.Plant
                                ? "/images/chevron-down.svg"
                                : "/images/chevron-right.svg"
                            }
                            alt="Toggle"
                            className="chevron-icon"
                          />
                          Plant
                        </div>
                        {sectionVisibility[plant.plant_id]?.Plant && (
                          <div className="sidenav-section-content">
                            {plantDetails[plant.plant_id]?.[
                              plant.plant_id
                            ][0] &&
                              Object.keys(
                                plantDetails[plant.plant_id]?.[
                                  plant.plant_id
                                ][0]
                              )
                                .filter(
                                  (fieldName) =>
                                    ![
                                      "Time",
                                      "Status",
                                      "Communication",
                                      "Alarm / Fault",
                                    ].includes(fieldName)
                                )
                                .map((fieldName, index) => (
                                  <label key={index}>
                                    <input
                                      type="checkbox"
                                      checked={seriesVisibility[fieldName]}
                                      onChange={() => {
                                        setNewSelectedPlantName(plant.name);
                                        setNewSelectedPlantId(plant.plant_id);
                                        setSelectedFieldNamess(fieldName);
                                        setNewSelectedDeviceKey(null);
                                        // setSelectedDeviceKey(null);
                                        toggleSeriesVisibility(
                                          fieldName,
                                          `${fieldName}`
                                        );
                                      }}
                                    />
                                    <span className="checkbox-label">
                                      {fieldName}
                                    </span>
                                  </label>
                                ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="sidenav-item">
                      <div className="sidenav-section">
                        <div
                          className="sidenav-submenu-header"
                          onClick={() =>
                            toggleSection(plant.plant_id, "Devices")
                          }
                        >
                          <img
                            src={
                              sectionVisibility[plant.plant_id]?.Devices
                                ? "/images/chevron-down.svg"
                                : "/images/chevron-right.svg"
                            }
                            alt="Toggle"
                            className="chevron-icon"
                          />
                          Devices
                        </div>
                        {sectionVisibility[plant.plant_id]?.Devices && (
                          <div className="sidenav-section-content">
                            {deviceData[plant.plant_id]?.map(
                              (device, index) => (
                                <div key={index} className="device-item">
                                  <div
                                    className="device-header"
                                    onClick={() => {
                                      toggleDeviceVisibility(
                                        plant.plant_id,
                                        device.device_id
                                      );
                                      fetchColumnNames(
                                        plant.plant_id,
                                        plant.name,
                                        device.device_type,
                                        device.deviceIndex,
                                        device
                                      );
                                    }}
                                  >
                                    <img
                                      src={
                                        deviceVisibility[plant.plant_id]?.[
                                          device.device_id
                                        ]
                                          ? "/images/chevron-down.svg"
                                          : "/images/chevron-right.svg"
                                      }
                                      alt="Toggle"
                                      className="chevron-icon"
                                    />
                                    <span className="devices">
                                      {device.device_type}-{device.deviceIndex}
                                    </span>
                                  </div>
                                  {deviceVisibility[plant.plant_id]?.[
                                    device.device_id
                                  ] && (
                                    <div className="device-details">
                                      {deviceDetails[plant.plant_id]?.[
                                        `${device.device_type}-${device.deviceIndex}`
                                      ]?.[device.deviceIndex] && (
                                        <div>
                                          {Object.keys(
                                            deviceDetails[plant.plant_id][
                                              `${device.device_type}-${device.deviceIndex}` // Use the unique key
                                            ][device.deviceIndex]
                                          )
                                            .filter(
                                              (fieldName) =>
                                                ![
                                                  "Time",
                                                  "Status",
                                                  "Communication",
                                                  "Alarm / Fault",
                                                ].includes(fieldName)
                                            )
                                            .map((fieldName, index) => (
                                              <div key={index}>
                                                <label className="checkbox-label">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      seriesVisibility[
                                                        fieldName
                                                      ]
                                                    }
                                                    onChange={() => {
                                                      setSelectedFieldNamess(
                                                        null
                                                      );
                                                      setNewSelectedPlantName(
                                                        plant.name
                                                      );
                                                      setNewSelectedPlantId(
                                                        plant.plant_id
                                                      );
                                                      setNewDeviceType(
                                                        device.device_type
                                                      );
                                                      setNewDeviceIndex(
                                                        device.deviceIndex
                                                      );
                                                      setNewSelectedDeviceKey(
                                                        `${device.device_type}-${device.deviceIndex}`
                                                      );
                                                      setNewDevice(device);
                                                      // fetchDeviceDetails(
                                                      //   plant.plant_id,
                                                      //   plant.name,
                                                      //   device.device_type,
                                                      //   device.deviceIndex,
                                                      //   device
                                                      // );
                                                      toggleSeriesVisibility(
                                                        fieldName,
                                                        `${fieldName}`
                                                      );
                                                    }}
                                                  />
                                                  <span className="checkbox-label">
                                                    {fieldName}
                                                  </span>
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div id="main" className="charts">
        {isLoading ? (
          <div className="isloading">
            <CircularProgress />
          </div>
        ) : isAnyCheckboxSelected ? (
          <div className="e-data">
            <EChart
              data={filteredChartData}
              seriesVisibility={seriesVisibility}
              formattedStartDate={formattedStartDate}
              formattedEndDate={formattedEndDate}
              deviceDetails={deviceDetails}
              seriesColors={seriesColors}
              seriesTypes={seriesTypes}
              selectedTimeInterval={selectedTimeInterval}
              isChecked={isChecked}
            />
            <div className="data-table">
              <DataTable
                selectedCheckboxes={selectedCheckboxes}
                updateCheckboxColor={(
                  updatedCheckboxes,
                  updatedSeriesColors
                ) => {
                  setSelectedCheckboxes(updatedCheckboxes);
                  setSeriesColors(updatedSeriesColors);
                }}
                updateCheckboxType={(updatedCheckboxes, updatedSeriesTypes) => {
                  setSelectedCheckboxes(updatedCheckboxes);
                  setSeriesTypes(updatedSeriesTypes);
                }}
                seriesColors={seriesColors}
                seriesTypes={seriesTypes} // Pass the seriesTypes state
              />
            </div>
          </div>
        ) : (
          <p className="no-data">No Data Selected</p>
        )}
      </div>
    </div>
  );
};

export default Charts;
