import React, { useEffect } from "react";
import * as echarts from "echarts";
import "./piechart.css";

const PieChart = ({ chartId, data }) => {
  useEffect(() => {
    const chartDom = document.getElementById(chartId);
    const myChart = echarts.init(chartDom);

    const option = {
      tooltip: {
        trigger: "item",
        backgroundColor: "black",
        textStyle: {
          color: "white",
        },
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "50%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
            },
          },
          labelLine: {
            show: false,
          },
          data: data.map((item) => ({
            ...item,
            itemStyle: { color: item.color }, // Assign color to each data point
          })),
        },
      ],
    };

    option && myChart.setOption(option);

    // Clean up the chart when the component unmounts
    return () => {
      myChart.dispose();
    };
  }, [chartId, data]);

  return <div id={chartId} className="pie"></div>;
};

export default PieChart;
