import { Outlet, Navigate } from 'react-router-dom'
import React from 'react'
import { decrypt } from 'n-krypta'

const PrivateRoute = ({ component, ...rest }) => {
  const decryptedObject = decrypt(
    localStorage.getItem('LOGIN-INFO') ?? '',
    'mantrac1234',
  )
  console.log(decryptedObject)
  const email = decryptedObject.email
  const role = decryptedObject.role
  const isLoggedIn = email && role
  const isEmailOnly = email && !role
  return isLoggedIn ? (
    <Outlet />
  ) : isEmailOnly ? (
    <Navigate to="/wait" />
  ) : (
    <Navigate to="/login" />
  )
}

export default PrivateRoute
