import React, { useEffect, useState } from "react";
import "./card.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import axios from "axios"; // Import Axios
import { decrypt } from "n-krypta";
import Swal from "sweetalert2";
import DeviceFormPopup from "./DeviceFormPopup";

export const Card = () => {
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [deviceHistory, setdeviceHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Track the selected item for editing
  const { plant_id } = useParams();

  const openPopup = (deviceId, deviceType, typeIndex) => {
    // Filter the device data to only include the data of the selected device ID
    const selectedDeviceData = deviceInfo.filter(
      (item) => item.device_id === deviceId
    );

    // Make a POST request to get additional parameters and types
    axios
      .post(
        `https://manconnect.net/mantrac-php/device-paramters.php?plant_id=${plant_id}&device_id=${deviceId}&device_type=${deviceType}&type_index=${typeIndex}`
      )
      .then((response) => {
        // Handle the response from the backend
        const additionalInputGroups = response.data;
        // Add additionalInputGroups to each selectedDeviceData item
        const updatedSelectedDeviceData = selectedDeviceData.map((item) => ({
          ...item,
          additionalInputGroups,
        }));
        // Pass the updated data to the popup component
        setIsPopupOpen(true);
        setSelectedItem(updatedSelectedDeviceData);
      })
      .catch((error) => {
        console.error("Error fetching additional parameters:", error);
      });
  };

  console.log(selectedItem);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const decryptedObject = decrypt(
    localStorage.getItem("LOGIN-INFO") ?? "",
    "mantrac1234"
  );

  useEffect(() => {
    // First API request to fetch device info
    axios
      .get(
        `https://manconnect.net/mantrac-php/device-data.php?plant_id=${plant_id}`
      )
      .then((response) => {
        const data = response.data;
        setDeviceInfo(data);

        // Assuming you want to send the first item from the response in the POST request
        const firstItem = data[0];

        // Create data to be sent in the POST request
        const dataToSend = {
          plant_name: firstItem.name, // Use data from the response
          device_type: firstItem.device_type, // Use data from the response
          // device_id: firstItem.device_id, // Use data from the response
        };

        console.log(dataToSend);
        const sendData = JSON.stringify(dataToSend);
        console.log(sendData);

        // Second API request (POST) to send data to the backend
        axios
          .post(
            "https://manconnect.net/mantrac-php/device-history.php",
            sendData
          )
          .then((response) => {
            const data = response.data;
            console.log(data);
            setdeviceHistory(data);
            setLoading(false);
            // Handle the response from the second POST request, if needed
            console.log("Response from second POST request:", data);
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              console.error(
                "Server responded with status:",
                error.response.status
              );
              setLoading(false);
              console.error("Response data:", error.response.data);
            } else if (error.request) {
              // The request was made but no response was received
              console.error("No response received from the server.");
            } else {
              // Something else happened while setting up the request
              console.error("Error setting up the request:", error.message);
            }
            console.error("Axios request error:", error.config);
          });
      })
      .catch((error) => {
        console.error("Error fetching device info:", error);
      });
  }, [plant_id]);

  const handleDelete = (deviceId, deviceType, typeIndex) => {
    // Make delete request to the backend
    axios
      .delete(
        `https://manconnect.net/mantrac-php/delete-device.php?plant_id=${plant_id}&device_id=${deviceId}&device_type=${deviceType}&type_index=${typeIndex}`
      )
      .then((response) => {
        console.log("Device deleted successfully:", response.data);
        // Refresh device info after deletion
        // You may need to modify this logic depending on how your backend behaves
        // For example, you may want to refetch the device info to reflect the changes
        setDeviceInfo(deviceInfo.filter((item) => item.device_id !== deviceId));
      })
      .catch((error) => {
        console.error("Error deleting device:", error);
      });
  };

  const handleEdit = (deviceId, deviceType, typeIndex) => {
    // Construct the data to be sent in the POST request

    // Make a POST request to send the data to the backend
    axios
      .post(
        `https://manconnect.net/mantrac-php/device-paramters.php?plant_id=${plant_id}&device_id=${deviceId}&device_type=${deviceType}&type_index=${typeIndex}`
      )
      .then((response) => {
        // Handle the response from the backend if needed
        console.log("Edit successful:", response.data);
        // Optionally, you can perform additional actions after the edit is successful
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error("Error editing device:", error);
      });
  };

  return (
    <div className="card-conatiner">
      <div className="card-list">
        {loading ? (
          <div className="centered-container">
            <CircularProgress />
          </div>
        ) : deviceInfo ? (
          deviceInfo.map((item, index) => (
            <div className="type-header-size" key={item.device_id}>
              {/* <button className="card-button"> */}
              <div className="card-header">
                <div className="card-header-content">
                  <div className="card-title-text">
                    {item.device_name}
                    {decryptedObject.role === "Admin" && (
                      <div className="card-buttons">
                        <button
                          className="edit-button"
                          onClick={() => {
                            openPopup(
                              item.device_id,
                              item.device_type,
                              item.type_index
                            );
                            // handleEdit(
                            //   item.device_id,
                            //   item.device_type,
                            //   item.type_index
                            // );
                          }}
                        >
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
                              fill="#dfdfdf"
                              // Add stroke property inline
                              stroke="none"
                            />
                          </svg>
                        </button>
                        <button
                          className="delete-button"
                          onClick={() => {
                            Swal.fire({
                              title: "Are you sure?",
                              text: "You won't be able to revert this!",
                              icon: "warning",
                              confirmButtonText: "Delete",
                              showCancelButton: true,
                              confirmButtonColor: "#d33",
                              cancelButtonColor: "#3085d6",
                              reverseButtons: true,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDelete(
                                  item.device_id,
                                  item.device_type,
                                  item.type_index
                                );
                                Swal.fire(
                                  "Deleted!",
                                  "Your device has been deleted.",
                                  "success"
                                );
                              } else if (
                                result.dismiss === Swal.DismissReason.cancel
                              ) {
                                Swal.fire({
                                  title: "Cancelled",
                                  text: "Your device is safe :)",
                                  icon: "error",
                                });
                              }
                            });
                          }}
                        >
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 12V17"
                              stroke="#dfdfdf"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14 12V17"
                              stroke="#dfdfdf"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4 7H20"
                              stroke="#dfdfdf"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10"
                              stroke="#dfdfdf"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                              stroke="#dfdfdf"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                    {/* <div className="card-delete"></div> */}
                  </div>
                  <div className="card-title-text2">
                    Device Seq: {item.device_type}-{item.type_index}
                  </div>
                </div>
              </div>
              <div className="card-title-line"></div>
              <div className="card-picture-content">
                <div className="card-content">
                  <div className="text-wrapper">Device Model</div>
                  <div className="text-wrapper2">{item.device_model}</div>
                  <div className="text-wrapper">Device S.N</div>
                  <div className="text-wrapper2">
                    {item.device_serialnumber}
                  </div>
                  <div className="text-wrapper">Device Brand</div>
                  <div className="text-wrapper2">{item.device_brand}</div>
                  <div className="text-wrapper">Device Type</div>
                  <div className="text-wrapper2">{item.device_type}</div>
                  <div className="text-wrapper">Device Rating</div>
                  <div className="text-wrapper2">{item.device_rating}</div>
                  <div className="text-wrapper">Total Active Power</div>
                  <div className="text-wrapper2">
                    {deviceHistory
                      ? deviceHistory["SG50CX Total Active Power(kW)"] + " kW"
                      : "No Data"}
                  </div>
                  <div className="text-wrapper">Creation Date</div>
                  <div className="text-wrapper2">{item.creation_date}</div>
                </div>
              </div>
              {/* </button> */}
            </div>
          ))
        ) : null}
      </div>
      {isPopupOpen && (
        <DeviceFormPopup selectedItem={selectedItem} onClose={closePopup} />
      )}
    </div>
  );
};
