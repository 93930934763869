import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../Components/Navbar";
import { Sidebar } from "../Components/Sidebar.tsx";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PlantSelectionPopup from "./PlantSelectionPopup"; // Import the PlantSelectionPopup component
import "./requests.css";

const Requests = () => {
  const [data, setData] = useState([]);
  const [updatedRoles, setUpdatedRoles] = useState({});
  const [plants, setPlants] = useState([]); // State for storing plants
  const [isPlantSelectionOpen, setIsPlantSelectionOpen] = useState(false); // State for the plant selection popup
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedPlants, setSelectedPlants] = useState([]);

  useEffect(() => {
    // Fetch user data
    const userDataUrl = "https://manconnect.net/mantrac-php/requests.php";
    axios
      .get(userDataUrl)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    // Fetch plants data
    const plantsDataUrl = "https://manconnect.net/mantrac-php/site_data.php";
    axios
      .get(plantsDataUrl)
      .then((response) => {
        setPlants(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleRoleChange = (id, role) => {
    setUpdatedRoles((prevRoles) => ({ ...prevRoles, [id]: role }));
  };

  const handleApprove = (id) => {
    const role = updatedRoles[id];

    if (!role || role === "") {
      // Role is not selected, show an error or handle it as needed
      console.error("Please select a role before approving.");
      return;
    }

    console.log("Selected User ID:", id);
    console.log("Selected Plants Length:", selectedPlants.length);

    // Call the API only if both user and plants are selected
    if (id && selectedPlants.length > 0) {
      // API call for role update
      const roleUpdateUrl =
        "https://manconnect.net/mantrac-php/update-role.php";
      axios
        .post(roleUpdateUrl, {
          id,
          role,
        })
        .then((response) => {
          const newData = data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                role,
              };
            }
            return item;
          });
          setData(newData);
          setUpdatedRoles({});
        })
        .catch((error) => {
          console.error("Error updating role:", error);
        });

      // API call for adding plants
      const addPlantsUrl =
        "https://manconnect.net/mantrac-php/add-user-plants.php";
      axios
        .post(addPlantsUrl, {
          userId: id,
          selectedPlants,
        })
        .then((response) => {
          console.log("Plants added successfully:", response.data);
          // Handle any additional logic or state updates as needed
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error adding plants:", error);
        });
    } else {
      // Plants are not selected, show an error or handle it as needed
      console.error("Please select plants before approving.");
    }
  };

  const handleDecline = (id) => {
    const url = "https://manconnect.net/mantrac-php/delete-user.php";
    axios
      .post(url, {
        id,
      })
      .then((response) => {
        const newData = data.filter((item) => item.id !== id);
        setData(newData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOpenPlantSelection = (id) => {
    setSelectedUserId(id);
    setIsPlantSelectionOpen(true);
  };

  const handleClosePlantSelection = () => {
    setSelectedUserId(null);
    setIsPlantSelectionOpen(false);
  };

  return (
    <>
      <div
        className="grid"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        {data.map((item) => (
          <Card className="card" key={item.id}>
            <AccountCircleIcon style={{ fontSize: 120 }} />
            <CardContent>
              <Typography style={{ whiteSpace: "pre-line" }} variant="h6">
                name: {item.name} {"\n"} email: {item.email} {"\n"} companyName:{" "}
                {item.companyName} {"\n"} id: {item.id} {"\n"} role:{" "}
                {updatedRoles[item.id] || item.role} {"\n"}
              </Typography>

              <select
                onChange={(e) => {
                  e.preventDefault();
                  handleRoleChange(item.id, e.target.value);
                }}
                value={updatedRoles[item.id] || item.role || ""}
              >
                <option value="" disabled>
                  Select Role
                </option>
                <option value="Admin">Admin</option>
                <option value="Customer">Customer</option>
              </select>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  handleApprove(item.id, item.role);
                }}
              >
                Approve
              </Button>
              <Button
                size="small"
                onClick={(e) => {
                  handleDecline(item.id);
                }}
              >
                Decline
              </Button>
              <Button
                size="small"
                onClick={() => handleOpenPlantSelection(item.id)}
              >
                Select Plants
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>

      {/* Plant Selection Popup */}
      {isPlantSelectionOpen && (
        <PlantSelectionPopup
          plants={plants}
          onClose={handleClosePlantSelection}
          onSelectPlants={(selectedPlants) => {
            // Handle selected plants for the user (selectedUserId)
            setSelectedPlants(selectedPlants);
            console.log(
              `Selected Plants for User ${selectedUserId}:`,
              selectedPlants
            );
            handleClosePlantSelection();
          }}
        />
      )}

      <Sidebar />
      <Navbar />
    </>
  );
};

export default Requests;
