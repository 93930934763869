import React, { useState, useEffect } from "react";
import "./files.css";
import axios from "axios";
import { decrypt } from "n-krypta";
import Dropzone from "react-dropzone";
import { Progress, message } from "antd";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

const Files = () => {
  const [previews, setPreviews] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const [messageApi, setMessageApi] = useState(null); // Initialize messageApi

  const decryptedObject = decrypt(
    localStorage.getItem("LOGIN-INFO") ?? "",
    "mantrac1234"
  );

  useEffect(() => {
    fetchPreviews();

    setMessageApi(message);
  }, []);

  const fetchPreviews = () => {
    setLoading(true); // Set loading to true when fetching previews
    axios
      .get("https://manconnect.net/mantrac-php/pdf-preview.php")
      .then((response) => {
        const previewsArray = Object.entries(response.data).map(
          ([filename, imageData]) => ({
            filename,
            imageData,
          })
        );
        setPreviews(previewsArray);
        setLoading(false); // Set loading to false when previews are fetched
      })
      .catch((error) => {
        console.error("Error fetching previews:", error);
        setLoading(false); // Set loading to false in case of error
      });
  };

  const errorMessage = (content) => {
    messageApi.error(content); // Use messageApi to display error message
  };

  const handleFileUpload = async (acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Initialize progress for the current file
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          { name: file.name, progress: 0 },
        ]);

        // Send the file to the PHP backend using Axios
        const response = await axios.post(
          "https://manconnect.net/mantrac-php/upload.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // Update the progress of the file upload
              setUploadedFiles((prevFiles) =>
                prevFiles.map((uploadedFile) =>
                  uploadedFile.name === file.name
                    ? { ...uploadedFile, progress: percentCompleted }
                    : uploadedFile
                )
              );
            },
          }
        );

        if (response.status === 200) {
          // Optionally, you can show a success message or update UI here
          console.log("File uploaded successfully");
          // Fetch updated previews
          fetchPreviews();
        } else {
          console.error("Failed to upload file:", response.statusText);
        }
      } catch (error) {
        // If an error occurs, handle it here
        console.error("Error uploading file:", error);
        // Access the response status if available
        if (error.response && error.response.status === 500) {
          // Set status to "exception" for files with 500 response status
          setUploadedFiles((prevFiles) =>
            prevFiles.map((uploadedFile) =>
              uploadedFile.name === file.name
                ? { ...uploadedFile, status: "exception" }
                : uploadedFile
            )
          );
          // Generate error message
          errorMessage(error.response.data);
        }
      }
    });
  };

  const handleDeleteFile = async (filename) => {
    try {
      // Send a POST request to delete-file.php
      const response = await axios.post(
        "https://manconnect.net/mantrac-php/delete-file.php",
        { filename: filename }
      );

      if (response.data.success) {
        // File deleted successfully
        console.log(response.data.message);
        // Fetch updated previews
        fetchPreviews();
        // Remove the deleted file from the uploaded files list
        setUploadedFiles((prevFiles) =>
          prevFiles.filter((file) => file.name !== filename)
        );
      } else {
        // Failed to delete file
        console.log(response.data);
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  return (
    <div>
      {loading ? ( // Render loading spinner if loading is true
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <div className="files-container">
          <div className="previews-container">
            {previews.map((preview, index) => (
              <div key={index} className="preview-item">
                <div className="preview-content">
                  <a
                    href={`https://manconnect.net/manuals/${preview.filename}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="preview-link"
                  >
                    <img
                      className="doc"
                      src={`data:image/jpeg;base64,${preview.imageData}`}
                      alt={`Preview ${index + 1}`}
                    />
                  </a>
                  {decryptedObject.role === "Admin" && (
                    <button
                      className="delete-doc"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          confirmButtonText: "Delete",
                          showCancelButton: true,
                          confirmButtonColor: "#d33",
                          cancelButtonColor: "#3085d6",
                          reverseButtons: true,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleDeleteFile(preview.filename);
                            Swal.fire(
                              "Deleted!",
                              "Your device has been deleted.",
                              "success"
                            );
                          } else if (
                            result.dismiss === Swal.DismissReason.cancel
                          ) {
                            Swal.fire({
                              title: "Cancelled",
                              text: "Your File Is Safe :)",
                              icon: "error",
                            });
                          }
                        });
                      }}
                    >
                      <img src="/images/delete.svg" alt="Delete" />
                    </button>
                  )}
                </div>
                <div className="filename">{preview.filename}</div>
              </div>
            ))}
            {decryptedObject.role === "Admin" && (
              <div className="upload-container">
                <div className="upload">
                  <Dropzone
                    onDrop={handleFileUpload}
                    accept={{
                      "application/pdf": [".pdf"], // Add PDF files here
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <div className="file-list">
                  <div className="files-title">Files:</div>
                  <ul>
                    {uploadedFiles.map((file, index) => (
                      <li key={index}>
                        {file.name}{" "}
                        {file.status !== "exception" ? (
                          <Progress percent={file.progress} size="small" />
                        ) : (
                          <Progress
                            percent={100}
                            size="small"
                            status="exception"
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Files;
