import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown.tsx";
import { decrypt } from "n-krypta";
import "./navbar.css";

const Navbar = () => {
  const pathnameParts = window.location.pathname.split("/");
  const plantId = pathnameParts[2];

  const navRef = useRef(null);
  const decryptedObject = decrypt(
    localStorage.getItem("LOGIN-INFO") ?? "",
    "mantrac1234"
  );

  const onScroll = () => {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 10) {
      if (!navRef.current.classList.contains("scrolled-down")) {
        navRef.current.classList.add("scrolled-down");
      }
    } else {
      if (navRef.current.classList.contains("scrolled-down")) {
        navRef.current.classList.remove("scrolled-down");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen)
  // }

  return (
    <nav ref={navRef} id="nav">
      <div className="container flex">
        <a href="/home">
          <img
            src="https://www.mantracgroup.com/media/n43jykgb/mantrac_logo_v1.svg"
            alt=""
            className="manconnect-img"
          />
        </a>
        {/* <div className="links">
          <a href="login">API</a>
          <a>Research</a>
          <a>Blog</a>
          <a>About</a>
        </div> */}
        <div className="create-plant">
          {window.location.pathname === "/home" &&
            decryptedObject.role === "Admin" && (
              <button>
                <a className="create-anchor" href="/create-plant">
                  <img src="/images/add.svg" alt="" className="add-img" />
                  Create Plant
                </a>
              </button>
            )}
        </div>
        <div className="create-device">
          {plantId && decryptedObject.role === "Admin" && (
            <button>
              <Link to={`/create-device/${plantId}`} className="create-anchor">
                <img src="/images/add.svg" alt="" className="add-img" />
                Add Device
              </Link>
            </button>
          )}
        </div>
        <Dropdown className="dropdown" />
      </div>
    </nav>
  );
};

export default Navbar;
