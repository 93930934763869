import React, { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";

const EChart = ({
  data,
  seriesVisibility,
  formattedStartDate,
  formattedEndDate,
  deviceDetails,
  seriesColors,
  seriesTypes,
  selectedTimeInterval,
  isChecked,
}) => {
  console.log("Colors:", seriesColors);
  console.log("seriesTypes:", seriesTypes);
  console.log("deviceDetails:", deviceDetails);
  console.log(data);
  console.log("selectedTimeInterval:", selectedTimeInterval);
  console.log("checked???", isChecked);

  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const theme = isChecked ? "dark" : "light";

    const myChart = echarts.init(chartDom, theme, {
      renderer: "svg",
    });

    const series = [];
    const yAxisArray = [];
    const ranges = [];

    let lastYAxisName = "";

    // Iterate over devices first
    Object.keys(deviceDetails).forEach((deviceId) => {
      // Check if the device has any visible series
      const visibleSeries = Object.keys(seriesVisibility).filter(
        (seriesName) =>
          seriesVisibility[seriesName] && deviceDetails[deviceId][seriesName]
      );

      visibleSeries.forEach((seriesName, index) => {
        const fieldName = seriesName;
        const seriesData = data
          .filter(
            (item) =>
              deviceDetails[deviceId][seriesName] &&
              item[seriesName] !== undefined
          )
          .map((item) => [item.Time, item[fieldName]]);

        // Calculate the range of values for each series
        const values = seriesData.map((item) => item[1]);
        const range = Math.max(...values) - Math.min(...values);

        // Store the range and other series details
        ranges.push({ seriesName, range });

        const type = seriesTypes[seriesName] || "line";

        const yAxisIndex = index % 2 === 0 ? 0 : 1;

        series.push({
          data: seriesData,
          type: type,
          name: `${seriesName}`,
          yAxisIndex: yAxisIndex,
          // barGap: "100%",

          itemStyle: {
            color: seriesColors[seriesName],
          },
          // Dynamically assign yAxisIndex based on the order of series
          // yAxisIndex: seriesOrder.length % 2 === 0 ? 0 : 1,
        });

        const matches = seriesName.match(/.*\(([^)]+)\)/);
        lastYAxisName = matches ? matches[1] : "";
        console.log(seriesName);
        console.log(lastYAxisName);
      });
    });

    // Assign y-axes based on the sorted order
    ranges.sort((a, b) => b.range - a.range);

    console.log(ranges);

    // Assign y-axes based on the sorted order
    ranges.forEach((item, index) => {
      const matches = item.seriesName.match(/\(([^)]+)\)/);
      const unitName = matches ? matches[1] : ""; // Use an empty string if matches is null

      yAxisArray.push({
        type: "value",
        name:
          index === 0
            ? `Unit: ${unitName}`
            : index === 1
            ? `Unit: ${unitName}`
            : "",
        position: index % 2 === 0 ? "left" : "right",
      });
    });

    const option = {
      legend: {
        type: "scroll",
        width: "83%",
      },
      toolbox: {
        feature: {
          // dataView: { show: true, readOnly: false },
          restore: {},
          saveAsImage: {},
        },
      },
      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100,
        },
        {
          start: 0,
          end: 10,
        },
      ],
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        min: formattedStartDate,
        max: formattedEndDate,
      },
      yAxis: yAxisArray,
      series: series,
    };

    option.xAxis.min = formattedStartDate;
    option.xAxis.max = formattedEndDate;

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [
    data,
    seriesVisibility,
    formattedStartDate,
    formattedEndDate,
    deviceDetails,
    seriesColors,
    seriesTypes,
    selectedTimeInterval,
    isChecked,
  ]);

  return <div ref={chartRef} style={{ height: "400px", width: "99%" }}></div>;
};

export default EChart;
