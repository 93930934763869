import React from "react";
import Navbar from "../Components/Navbar";
import { Sidebar } from "../Components/Sidebar.tsx";
import { PlantForm } from "../Components/PlantForm";

const CreatePlant = () => {
  return (
    <div style={{ height: "auto" }}>
      <Sidebar />
      <Navbar />
      <PlantForm />
    </div>
  );
};

export default CreatePlant;
