import React, { useEffect } from "react";
import * as echarts from "echarts";

const AreaChart = ({ chartData, selectedOption }) => {
  console.log(chartData);
  useEffect(() => {
    // Create a new ECharts instance
    const chartDom = document.getElementById("main");
    const myChart = echarts.init(chartDom);

    // Define the chart option using the provided data
    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        data: chartData.map((data) => data.name),
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: chartData[0]?.values.map((entry) => entry.time) || [],
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Irradiance(W/m²)", // Y-axis on the right
        },
        {
          type: "value",
          name: "Active Power(kW)", // Y-axis on the left
        },
      ],
      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 150,
        },
        {
          show: false,
          start: 0,
          end: 150,
        },
      ],
      series: chartData.map((data, index) => ({
        name: data.name,
        type:
          selectedOption === "Total" ||
          selectedOption === "Annual" ||
          selectedOption === "Monthly"
            ? "bar"
            : "line",
        areaStyle: {},
        smooth: true,
        yAxisIndex: index % 2, // Use index to alternate between the two y-axes
        data: data.values,
      })),
    };

    // Set the option for the chart
    option && myChart.setOption(option);

    // Handle window resize to resize the chart
    const handleResize = () => {
      myChart.resize();
    };
    window.addEventListener("resize", handleResize);

    // Clean up event listener and the chart when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, [chartData, selectedOption]);

  return <div id="main" style={{ width: "100%", height: "400px" }}></div>;
};

export default AreaChart;
