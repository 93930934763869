import React from "react";
import Navbar from "../Components/Navbar";
import { Sidebar } from "../Components/Sidebar.tsx";
import { DeviceForm } from "../Components/DeviceForm";

const CreateDevice = () => {
  return (
    <div style={{ height: "auto" }}>
      <Sidebar />
      <Navbar />
      <DeviceForm />
    </div>
  );
};

export default CreateDevice;
