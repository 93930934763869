// PlantSelectionPopup.jsx
import React, { useState } from "react";
import "./PlantSelectionPopup.css"; // Import the CSS file

const PlantSelectionPopup = ({ plants, onClose, onSelectPlants }) => {
  const [selectedPlants, setSelectedPlants] = useState({});

  const handleTogglePlant = (plantId, isCheckbox) => {
    // Check if the clicked element is a checkbox
    if (isCheckbox) {
      setSelectedPlants((prevSelected) => ({
        ...prevSelected,
        [plantId]: !prevSelected[plantId],
      }));
    }
  };

  const handleConfirm = () => {
    // Get the selected plant IDs
    const selectedPlantIds = Object.keys(selectedPlants).filter(
      (plantId) => selectedPlants[plantId]
    );

    // Log the selected plant IDs
    console.log("Selected Plants:", selectedPlantIds);

    // Call the onSelectPlants callback with the selected plant IDs
    onSelectPlants(selectedPlantIds);

    // Close the popup
    onClose();
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h2>Select Plants</h2>
        <ul className="notes-list">
          {plants.map((plant) => (
            <li key={plant.plant_id} className="plant-item">
              <span onClick={() => handleTogglePlant(plant.plant_id, false)}>
                {plant.name}
              </span>
              <input
                type="checkbox"
                onChange={() => handleTogglePlant(plant.plant_id, true)}
              />
            </li>
          ))}
        </ul>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
        <button className="add-note-button" onClick={handleConfirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default PlantSelectionPopup;
