import React, { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import "./datatable.css";

const DataTable = ({
  selectedCheckboxes,
  updateCheckboxColor,
  seriesColors,
  updateCheckboxType,
  seriesTypes,
}) => {
  const [colorPickerIndex, setColorPickerIndex] = useState(null);
  const colorPickerRef = useRef(null);
  const tdRefs = useRef([]);

  const toggleColorPicker = (index) => {
    // Toggle the state for the clicked row
    setColorPickerIndex(colorPickerIndex === index ? null : index);
  };

  const handleColorChange = (color, index) => {
    // Update the color in selectedCheckboxes array
    const updatedCheckboxes = [...selectedCheckboxes];
    updatedCheckboxes[index].color = color;

    // Update the color in seriesColors using the field name as the key
    const updatedSeriesColors = { ...seriesColors };
    updatedSeriesColors[selectedCheckboxes[index].selectedFieldName] = color;

    // Call the function to update the color in the parent component
    updateCheckboxColor(updatedCheckboxes, updatedSeriesColors);
  };

  const handleTypeChange = (event, index) => {
    // Update the type in selectedCheckboxes array
    const updatedCheckboxes = [...selectedCheckboxes];
    updatedCheckboxes[index].type = event.target.value;

    // Update the type in seriesTypes using the field name as the key
    const updatedSeriesTypes = { ...seriesTypes };
    updatedSeriesTypes[selectedCheckboxes[index].selectedFieldName] =
      event.target.value;

    // Call the function to update the type in the parent component
    updateCheckboxType(updatedCheckboxes, updatedSeriesTypes);
  };

  const handleOutsideClick = (event) => {
    // Close the color picker if clicked outside the specific td element
    if (
      tdRefs.current &&
      tdRefs.current.length > 0 &&
      !tdRefs.current.some(
        (tdRef, index) =>
          tdRef.contains(event.target) && index === colorPickerIndex
      ) &&
      colorPickerIndex !== null
    ) {
      // Close the open color picker
      setColorPickerIndex(null);
    }
  };

  // Attach an event listener for outside clicks
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [colorPickerIndex]);

  // Extract Plant and Measuring Point data from selectedCheckboxes
  const tableData = selectedCheckboxes.map((checkbox, index) => ({
    display: checkbox.color, // Updated to use the color from selectedCheckboxes
    measuringPoint: checkbox.selectedDeviceKey
      ? `${checkbox.selectedDeviceKey}/${checkbox.selectedFieldName}`
      : `${checkbox.selectedPlantName}/${checkbox.selectedFieldName}`,
    plant: checkbox.selectedPlantName,
    type: checkbox.type, // Use the type from state
    statisticalPattern: "", // Add the appropriate statistical pattern data here
  }));

  return (
    <div className="table-data">
      <table>
        <thead>
          <tr>
            {/* Display */}
            <th>Display</th>

            {/* Measuring Point */}
            <th>Measuring Point</th>

            {/* Plant */}
            <th>Plant</th>

            {/* Type */}
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {/* Display data */}
              <td
                ref={(ref) => {
                  tdRefs.current[rowIndex] = ref;
                }}
              >
                <div
                  className="display-color"
                  style={{
                    backgroundColor: rowData.display,
                  }}
                  onClick={() => toggleColorPicker(rowIndex)}
                ></div>
                {colorPickerIndex === rowIndex && (
                  <div className="color-picker-container" ref={colorPickerRef}>
                    <SketchPicker
                      color={rowData.display}
                      onChange={(color) =>
                        handleColorChange(color.hex, rowIndex)
                      }
                    />
                  </div>
                )}
              </td>

              {/* Measuring Point data */}
              <td>{rowData.measuringPoint}</td>

              {/* Plant data */}
              <td>{rowData.plant}</td>

              {/* Type data */}
              <td>
                <select
                  value={rowData.type}
                  onChange={(event) => handleTypeChange(event, rowIndex)}
                >
                  <option value={rowData.type}>
                    {rowData.type.charAt(0).toUpperCase() +
                      rowData.type.slice(1)}
                  </option>

                  <option value={rowData.type === "line" ? "bar" : "line"}>
                    {rowData.type === "line" ? "Bar" : "Line"}
                  </option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
