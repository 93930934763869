import './verification.css'
import { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const Verification = () => {
  const navigate = useNavigate()
  const [code, setCode] = useState('')
  const urlParams = new URLSearchParams(window.location.search)
  const email = urlParams.get('email')
  const verification_code = urlParams.get('code')

  const handleSubmit = (e) => {
    e.preventDefault()
    axios
      .post('https://manconnect.net/mantrac-php/verify-email.php', {
        email,
        verification_code,
        user_code: code,
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire('Congratulations!', 'Verification successful', 'success')
          navigate('/login')
        } else {
          Swal.fire('Oops...', 'Verification failed', 'error')
        }
      })
      .catch((error) => {
        // Handle error
        Swal.fire('Oops...', 'Something Went Wrong!', 'error')
      })
  }

  return (
    <div className="verification">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <img
            className="form-img"
            src="https://www.mantracgroup.com/media/23rdrnwg/mansour-timeline-2000-2x-1024x768.jpg?width=470"
            alt=""
          ></img>
          <h3 className="form-title">
            <b>Verification Code</b>
          </h3>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Verification Code"
            className="form-control mt-3"
          />
          <div className="d-grid gap-1 mt-3">
            <button type="submit" className="btn btn-primary">
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Verification
