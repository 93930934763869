import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import "./sidebar.css";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import AddBoxIcon from "@mui/icons-material/AddBox";
import LockIcon from "@mui/icons-material/Lock";
import StorageIcon from "@mui/icons-material/Storage";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const API_ENDPOINT = "https://manconnect.net/mantrac-php/plant-sidebar.php";

const icons = {
  menu: <MenuIcon />,
  home: <img alt="" src="/images/overview.svg" />,
  settings: <img src="/images/nigeria.png" style={{ width: 30, height: 30 }} />,
  add_box: <AddBoxIcon />,
  lock: <img alt="" src="/images/electricity.svg" />,
  inventory_2: <img alt="" src="/images/chart.svg" />,
  favorite: <img alt="" src="/images/warning.svg" />,
  files: <img alt="" src="/images/folder.svg" />,
  search: <SearchIcon />,
  person: <PersonIcon />,
  less: <ExpandLessIcon />,
  more: <ExpandMoreIcon />,
};

type Item = {
  name: string;
  icon: string;
  items: string[];
};

const Icon = ({ icon }: { icon: string }) => (
  <span className="material-icons-outlined">{icons[icon]}</span>
);

const NavHeader: FC<{ plantName: string | null }> = ({ plantName }) => (
  <header className="sidebar-header">
    <img alt="" src="/images/plant.jpeg" />
    <span className="plant-name">{plantName}</span>
  </header>
);

type ButtonProps = {
  onClick: (item: string) => void;
  name: string;
  icon?: string;
  isActive: boolean;
  hasSubNav?: boolean;
  link?: string;
};

const NavButton: FC<ButtonProps> = ({
  onClick,
  name,
  icon,
  isActive,
  hasSubNav,
  link,
}) => (
  <a href={link}>
    <button
      type="button"
      onClick={() => {
        onClick(name);
      }}
      className={`navButton ${isActive ? "active" : ""}`}
    >
      {icon && <Icon icon={icon} />}
      <span className="iconName">{name}</span>
      <div className="expand">
        {hasSubNav && <Icon icon={isActive ? "less" : "more"} />}
      </div>
    </button>
  </a>
);

type SubMenuProps = {
  item: Item;
  activeItem: string;
  handleClick: (args0: string) => void;
};

const SubMenu: FC<SubMenuProps> = ({ item, activeItem, handleClick }) => {
  const navRef = useRef<HTMLDivElement>(null);

  const isSubNavOpen = (item: string, items: string[]) =>
    items.some((i) => i === activeItem) || item === activeItem;

  return (
    <div
      className={`sub-nav ${isSubNavOpen(item.name, item.items) ? "open" : ""}`}
      style={{
        height: !isSubNavOpen(item.name, item.items)
          ? 0
          : navRef.current?.clientHeight,
      }}
    >
      <div ref={navRef} className="sub-nav-inner">
        {item?.items.map((subItem, id) => (
          <React.Fragment key={id}>
            <NavButton
              onClick={handleClick}
              name={subItem}
              isActive={activeItem === subItem}
              link={`/${subItem.toLowerCase()}`}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export const Sidebar = () => {
  const [activeItem, setActiveItem] = useState<string>("");
  const { plant_id } = useParams();
  const [currentPlantId, setCurrentPlantId] = useState<string | null>(
    plant_id || localStorage.getItem("currentPlantId") || null
  );
  const [plantName, setPlantName] = useState<string | null>(null);

  useEffect(() => {
    // Function to fetch plant name based on plant_id
    const fetchPlantName = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}?plant_id=${plant_id}`);
        const data = await response.json();
        setPlantName(data[0]?.name || null); // Adjust the key based on your API response
      } catch (error) {
        console.error("Error fetching plant name:", error);
      }
    };

    if (plant_id) {
      setCurrentPlantId(plant_id);
      localStorage.setItem("currentPlantId", plant_id);
      fetchPlantName(); // Fetch the plant name when plant_id changes
    }
  }, [plant_id]);

  const menuItems = [
    {
      name: "Overview",
      icon: "home",
      id: "overview",
      link: `/overview/${currentPlantId || ""}`,
    },
    // {
    //   name: "Settings",
    //   icon: "settings",
    //   items: ["Display", "Editor", "Theme", "Interface"],
    //   id: "settings",
    // },
    // {
    //   name: "Create",
    //   icon: "add_box",
    //   items: ["Article", "Document", "Report"],
    //   id: "3",
    // },
    {
      name: "Device Information",
      icon: "lock",
      // items: ["Dashboard", "Logout"],
      id: "devices",
      link: `/plant/${currentPlantId || ""}`,
    },
    {
      name: "Charts",
      icon: "inventory_2",
      id: "curves",
      link: `/curves/${currentPlantId || ""}`,
    },
    {
      name: "Fault",
      icon: "favorite",
      id: "fault",
      link: `/fault/${currentPlantId || ""}`,
    },
    {
      name: "Files",
      icon: "files",
      id: "files",
      link: "/files/",
    },
    // {
    //   name: "Search",
    //   icon: "search",
    //   id: "search",
    // },
    // {
    //   name: "Users",
    //   icon: "person",
    //   id: "person",
    // },
  ];

  const handleClick = (item: string) => {
    // console.log("activeItem", activeItem);
    setActiveItem(item !== activeItem ? item : "");
  };

  return (
    <aside className="sidebar">
      <NavHeader plantName={plantName} />
      {menuItems.map((item, id) => (
        <React.Fragment key={id}>
          {!item.items && (
            <NavButton
              onClick={handleClick}
              name={item.name}
              icon={item.icon}
              isActive={activeItem === item.name}
              hasSubNav={!!item.items}
              link={item.link}
            />
          )}
          {item.items && (
            <>
              <NavButton
                onClick={handleClick}
                name={item.name}
                icon={item.icon}
                isActive={activeItem === item.name}
                hasSubNav={!!item.items}
              />
              <SubMenu
                key={item.id}
                activeItem={activeItem}
                handleClick={handleClick}
                item={item}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </aside>
  );
};
