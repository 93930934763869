import React from "react";
import Navbar from "../Components/Navbar";
import Files from "../Components/Files";
import { Sidebar } from "../Components/Sidebar.tsx";

const Folder = () => {
  return (
    <div className="home">
      <Sidebar />
      <Files />
      <Navbar />
    </div>
  );
};

export default Folder;
